import { DEFAULT_CURRENCY } from '@/enums/currency';

export default {
  isAuthorized: false,
  mainUser: null,
  currency: DEFAULT_CURRENCY,
  currentUserId: null,
  availableUsers: [],
  domains: [],
  isExpired: false,
  avatarUrls: {},
};
