import Vue from 'vue';
import { updateField } from 'vuex-map-fields';
import { OPTIONS } from '../../enums';

export const SET_GROUPS = 'SET_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const REPLACE_GROUP = 'REPLACE_GROUP';
export const DELETE_GROUP_ID = 'DELETE_GROUP_ID';
export const SET_FUNNELS_LOADING = 'SET_FUNNELS_LOADING';
export const SET_FUNNELS = 'SET_FUNNELS';
export const ADD_FUNNEL = 'ADD_FUNNEL';
export const SET_LATEST_FUNNELS = 'SET_LATEST_FUNNELS';
export const SET_ARCHIVE_COUNT = 'SET_ARCHIVE_COUNT';
export const SET_ALL_COUNT = 'SET_ALL_COUNT';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_PAGE_LIMIT = 'SET_PAGE_LIMIT';
export const DELETE_FUNNEL_ID = 'DELETE_FUNNEL_ID';
export const TOGGLE_GROUP_OPEN = 'TOGGLE_GROUP_OPEN';
export const SET_CHECK_FUNNEL_ID = 'SET_CHECK_FUNNEL_ID';
export const SET_CHECK_FUNNEL = 'SET_CHECK_FUNNEL';
export const SET_CHECK_GROUP = 'SET_CHECK_GROUP';
export const UNCHECK_FUNNELS = 'UNCHECK_FUNNELS';
export const UNCHECK_GROUPS_FUNNELS = 'UNCHECK_GROUPS_FUNNELS';

const uncheck = (objs, ids) => {
  objs
    .filter((obj) => ids.includes(obj.id))
    .forEach((obj) => Vue.set(obj, 'checked', false));
};

const mutations = {
  updateField,

  [SET_GROUPS]: (state, value) => {
    const openedGroupsId = (window.localStorage.getItem('openedFunnelGroups') || '').split(',');
    openedGroupsId.forEach((id) => {
      const group = value.find((g) => g.id === id);
      if (group) {
        group.opened = true;
      }
    });
    state.groups = value;
  },

  [ADD_GROUP]: (state, value) => {
    state.groups.unshift(value);
  },

  [REPLACE_GROUP]: (state, group) => {
    if (group && group.id) {
      const index = state.groups.findIndex((g) => g.id === group.id);
      if (index >= 0) state.groups.splice(index, 1, group);
    }
  },

  [DELETE_GROUP_ID]: (state, id) => {
    const index = state.groups.findIndex((g) => g.id === id);
    if (index >= 0) state.groups.splice(index, 1);
  },

  [SET_FUNNELS_LOADING]: (state, value) => {
    state.funnelsLoading = value;
  },
  [SET_FUNNELS]: (state, value) => {
    state.funnels = value;
  },
  [ADD_FUNNEL]: (state, funnel) => {
    state.funnels.unshift(funnel);
    if (state.pagination) {
      Vue.set(state.pagination, 'count', state.pagination.count + 1);
    }
  },
  [SET_LATEST_FUNNELS]: (state, value) => {
    state.latestFunnels = value;
  },

  [SET_ARCHIVE_COUNT]: (state, value) => {
    state.archiveCount = value;
  },

  [SET_ALL_COUNT]: (state, value) => {
    state.allCount = value;
  },

  [SET_PAGINATION]: (state, meta) => {
    state.pagination = {
      page: state.page,
      count: meta && meta.count,
      total: meta && meta.pages ? meta.pages : 1,
      range: OPTIONS.PAGINATION_RANGE,
      limit: state.limit,
      options: OPTIONS.LIMITS,
    };
  },

  [SET_PAGE_LIMIT]: (state, { page, limit }) => {
    if (page) state.page = page;
    if (limit) state.limit = limit;
  },

  [DELETE_FUNNEL_ID]: (state, id) => {
    const index = state.funnels.findIndex((f) => f.id === id);
    if (index >= 0) state.funnels.splice(index, 1);
  },

  [TOGGLE_GROUP_OPEN]: (state, id) => {
    const group = state.groups.find((g) => g.id === id);
    Vue.set(group, 'opened', !group.opened);

    const openedGroups = state.groups.filter((g) => g.opened).map((g) => g.id);
    window.localStorage.setItem('openedFunnelGroups', openedGroups.toString());
  },

  [SET_CHECK_FUNNEL_ID]: (state, { checked, id }) => {
    const funnel = state.funnels.find((f) => f.id === id);
    Vue.set(funnel, 'checked', checked);
  },

  [SET_CHECK_FUNNEL]: (state, { checked, groupId, funnelId }) => {
    const funnel = state.funnels.find((f) => f.id === funnelId);
    Vue.set(funnel, 'checked', checked);
    const isAllChecked = state.funnels
      .filter((f) => f.groupId === groupId)
      .every((f) => f.checked);
    const group = state.groups.find((g) => g.id === groupId);
    Vue.set(group, 'checked', isAllChecked);
  },

  [SET_CHECK_GROUP]: (state, { checked, id }) => {
    const group = state.groups.find((g) => g.id === id);
    Vue.set(group, 'checked', checked);
    state.funnels
      .filter((f) => f.groupId === id)
      .forEach((f) => { f.checked = checked; });
  },

  [UNCHECK_FUNNELS]: (state, ids) => {
    uncheck(state.funnels, ids);
  },

  [UNCHECK_GROUPS_FUNNELS]: (state, funnelIds) => {
    const funnel = state.funnels.filter((f) => funnelIds.includes(f.id));
    const groupIds = funnel.map((f) => f.groupId);
    uncheck(funnel, funnelIds);
    uncheck(state.groups, groupIds);
  },
};

export default mutations;
