import getApiAxios from './lib/apiAxios';

/**
 * Class for manupulate cloud with ez-builder API.
 * @module CloudRepository
 */
class CloudRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-builder API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = getApiAxios(axiosInstance, { baseURL: `${apiUrl}/v1/` });
    this.apiUrl = apiUrl;
  }

  /**
   * get file
   *
   * @param {*} params
   */
  async getFile(id) {
    const { data } = await this.axios.get(`files/${id}`);
    return data;
  }

  async getFiles(ids) {
    const { data } = await this.axios.get('files', {
      params: {
        'filter[files][and][id][in]': ids.join(','),
        'page[size]': 1000,
      },
    });
    return data;
  }
}

export default CloudRepository;
