import axios from 'axios';

export default function (axiosInstance, config = {}) {
  const instanceConfig = axiosInstance ? axiosInstance.defaults : {};
  const newAxiosInstance = axios.create({
    ...instanceConfig,
    ...config,
    validateStatus: (status) => status === 200,
    withCredentials: true,
  });

  Object.keys(axiosInstance.interceptors).forEach((type) => {
    axiosInstance.interceptors[type].handlers.forEach(
      ({ fulfilled, rejected }) => newAxiosInstance.interceptors[type].use(fulfilled, rejected),
    );
  });

  return newAxiosInstance;
}
