import * as FUNNELS from './funnels';

export default {
  title: 'What is your goal?',
  name: 'goal',
  i18n: 'goal',
  type: 'radio',
  options: [
    {
      title: 'I want to sell a product',
      image: 'goal-sell.png',
      main_image: 'goal-sell.png',
      value: 'sell',
      funnelmap: [
        {
          group: 'sales',
          steps: [
            ['order-form'],
            ['upsell', 'downsell'],
          ],
        },
        {
          group: 'finish',
          steps: [
            ['order-thank-you'],
          ],
        },
      ],
      next: {
        title: 'How would you like to sell your product?',
        type: 'radio',
        name: 'how',
        i18n: 'how-sell-product',
        options: [
          {
            title: 'Sell through a salespage',
            image: 'how-sell-product-salespage.png',
            main_image: 'how-sell-product-salespage.png',
            value: 'salespage',
            funnelmap: [
              {
                group: 'sales',
                steps: [
                  ['sales'],
                  ['order-form'],
                  ['upsell', 'downsell'],
                ],
              },
              {
                group: 'finish',
                steps: [
                  ['order-thank-you'],
                ],
              },
            ],
            next: {
              type: 'boolean',
              name: 'get-leads',
              i18n: 'get-leads',
              title: 'Get leads before sales page?',
              options: [
                {
                  value: true,
                  image: 'get-leads-yes.png',
                  main_image: 'get-leads-yes.png',
                  next: FUNNELS.OPTIN,
                  funnelmap: [
                    {
                      group: 'optin',
                      steps: [['optinpage']],
                    },
                    {
                      group: 'sales',
                      steps: [
                        ['sales'],
                        ['order-form'],
                        ['upsell', 'downsell'],
                      ],
                    },
                    {
                      group: 'finish',
                      steps: [
                        ['order-thank-you'],
                      ],
                    },
                  ],
                },
                {
                  value: false,
                  next: {
                    title: 'What would you like to sell?',
                    type: 'radio',
                    name: 'what',
                    i18n: 'what-sell',
                    options: [
                      {
                        title: 'Sell my own product',
                        image: 'what-sell-own.png',
                        main_image: 'what-sell-own-salespage.png',
                        value: 'own',
                        next: FUNNELS.SALESPAGE,
                        testAttr: 'sell-own-product',
                      },
                      {
                        title: 'Sell an affiliate product',
                        image: 'what-sell-affiliate.png',
                        main_image: 'what-sell-affiliate-salespage.png',
                        value: 'affiliate',
                        funnelmap: [
                          {
                            group: 'sales',
                            steps: [['sales']],
                          },
                        ],
                        next: FUNNELS.SALESPAGE_AFFILIATE,
                        testAttr: 'sell-affiliate-product',
                      },
                    ],
                  },
                },
              ],
            },
            testAttr: 'sell-through-salespage',
          },
          {
            title: 'Sell through a webinar',
            image: 'how-sell-product-webinar.png',
            main_image: 'how-sell-product-webinar.png',
            value: 'webinar',
            funnelmap: [
              {
                group: 'optin',
                steps: [
                  ['Web. Optin'],
                  ['optin-thank-you'],
                ],
              },
              {
                group: 'webinar',
                steps: [
                  ['webinar-countdown'],
                  ['webinar-room-card'],
                  ['webinar-replay'],
                ],
              },
              {
                group: 'sales',
                steps: [
                  ['order-form'],
                  ['upsell', 'downsell'],
                ],
              },
              {
                group: 'finish',
                steps: [
                  ['order-thank-you'],
                ],
              },
            ],
            next: {
              type: 'boolean',
              name: 'with-questionary',
              i18n: 'with-questionary',
              title: 'Questionary after webinar?',
              options: [
                {
                  value: true,
                  image: 'sell-with-questionary-yes.png',
                  main_image: 'sell-with-questionary-yes.png',
                  funnelmap: [
                    {
                      group: 'optin',
                      steps: [
                        ['Web. Optin'],
                        ['optin-thank-you'],
                      ],
                    },
                    {
                      group: 'webinar',
                      steps: [
                        ['webinar-countdown'],
                        ['webinar-room-card'],
                        ['webinar-replay'],
                      ],
                    },
                    {
                      group: 'sales',
                      steps: [
                        ['order-form'],
                        ['upsell', 'downsell'],
                      ],
                    },
                    {
                      group: 'finish',
                      steps: [
                        ['canditatureform'],
                        ['order-thank-you'],
                      ],
                    },
                  ],
                  next: FUNNELS.WEBINAR_APPLICATIONFORM,
                },
                {
                  value: false,
                  image: 'sell-with-questionary-no.png',
                  main_image: 'sell-with-questionary-no.png',
                  next: {
                    title: 'What would you like to sell?',
                    type: 'radio',
                    name: 'what',
                    i18n: 'what-sell',
                    options: [
                      {
                        title: 'Sell my own product',
                        image: 'what-sell-own.png',
                        main_image: 'what-sell-own.png',
                        value: 'own',
                        next: FUNNELS.WEBINAR,
                      },
                      {
                        title: 'Sell an affiliate product',
                        image: 'what-sell-affiliate.png',
                        main_image: 'what-sell-affiliate.png',
                        value: 'affiliate',
                        funnelmap: [
                          {
                            group: 'optin',
                            steps: [
                              ['Web. Optin'],
                              ['optin-thank-you'],
                            ],
                          },
                          {
                            group: 'webinar',
                            steps: [
                              ['webinar-countdown'],
                              ['webinar-room-card'],
                              ['webinar-replay'],
                            ],
                          },
                        ],
                        next: FUNNELS.FREE_WEBINAR_AFFILIATE,
                      },
                    ],
                  },
                },
              ],
            },
            testAttr: 'sell-through-webinar',
          },
          {
            title: 'Send visitors directly to the order form',
            image: 'how-sell-product-orderform.png',
            main_image: 'how-sell-product-orderform.png',
            value: 'orderform',
            funnelmap: [
              {
                group: 'sales',
                steps: [
                  ['order-form'],
                  ['upsell', 'downsell'],
                ],
              },
              {
                group: 'finish',
                steps: [
                  ['order-thank-you'],
                ],
              },
            ],
            next: FUNNELS.ORDERFORM,
            testAttr: 'send-visitors-directly-orderform',
          },
        ],
      },
      testAttr: 'sell-a-product',
    },
    {
      image: 'goal-lead.png',
      main_image: 'goal-lead.png',
      title: 'I want to gather leads',
      value: 'lead',
      funnelmap: [
        {
          group: 'finish',
          steps: [
            ['order-thank-you'],
          ],
        },
      ],
      next: {
        title: 'How would you like to gather leads?',
        type: 'radio',
        i18n: 'how-gather-leads',
        name: 'how',
        options: [
          {
            title: 'Market demand analysis',
            image: 'how-gather-leads-market-demand-analysis.png',
            main_image: 'how-gather-leads-market-demand-analysis.png',
            value: 'market-demand-analysis',
            funnelmap: [
              {
                group: 'optin',
                steps: [
                  ['canditatureform'],
                ],
              },
              {
                group: 'finish',
                steps: [
                  ['order-thank-you'],
                ],
              },
            ],
            next: FUNNELS.APPLICATIONFORM,
            testAttr: 'market-demand-analysis',
          },
          {
            title: 'Offer a free product as lead magnet',
            image: 'how-gather-leads-offer-a-free-product-as-lead-magnet.png',
            main_image: 'how-gather-leads-offer-a-free-product-as-lead-magnet.png',
            value: 'offer-a-free-product-as-lead-magnet',
            funnelmap: [
              {
                group: 'optin',
                steps: [
                  ['optinpage'],
                ],
              },
              {
                group: 'finish',
                steps: [
                  ['order-thank-you'],
                ],
              },
            ],
            next: {
              type: 'boolean',
              i18n: 'use-webinar',
              name: 'use-webinar',
              title: 'Use webinar as lead magnet?',
              options: [
                {
                  value: true,
                  image: 'use-webinar-yes.png',
                  main_image: 'use-webinar-yes.png',
                  funnelmap: [
                    {
                      group: 'optin',
                      steps: [
                        ['Web. Optin'],
                        ['optin-thank-you'],
                      ],
                    },
                    {
                      group: 'webinar',
                      steps: [
                        ['webinar-countdown'],
                        ['webinar-room-card'],
                        ['webinar-replay'],
                      ],
                    },
                    {
                      group: 'finish',
                      steps: [
                        ['order-thank-you'],
                      ],
                    },
                  ],
                  next: {
                    type: 'boolean',
                    i18n: 'with-questionary',
                    name: 'with-questionary',
                    title: 'Use questionary after webinar?',
                    options: [
                      {
                        value: true,
                        image: 'with-questionary-yes.png',
                        main_image: 'with-questionary-yes.png',
                        next: FUNNELS.FREE_WEBINAR_APPLICATIONFORM,

                        funnelmap: [
                          {
                            group: 'optin',
                            steps: [
                              ['Web. Optin'],
                              ['optin-thank-you'],
                            ],
                          },
                          {
                            group: 'webinar',
                            steps: [
                              ['webinar-countdown'],
                              ['webinar-room-card'],
                              ['webinar-replay'],
                            ],
                          },
                          {
                            group: 'finish',
                            steps: [
                              ['canditatureform'],
                              ['order-thank-you'],
                            ],
                          },
                        ],
                      },
                      {
                        value: false,
                        image: 'with-questionary-no.png',
                        main_image: 'with-questionary-no.png',
                        next: FUNNELS.FREE_WEBINAR,
                      },
                    ],
                  },
                },
                {
                  value: false,
                  image: 'use-webinar-no.png',
                  main_image: 'use-webinar-no.png',
                  next: FUNNELS.OPTIN_APPLICATIONFORM,
                },
              ],
            },
            testAttr: 'free-product-lead-magnet',
          },
        ],
      },
      testAttr: 'gather-leads',
    },
  ],
  funnelmapTestAttrs: {
    'order-form': 'order-form',
    upsell: 'upsell',
    downsell: 'downsell',
    'order-thank-you': 'thanks-page',
    sales: 'sales-page',
    optinpage: 'optinpage',
    'Web. Optin': 'web-optin',
    canditatureform: 'app-form',
    'optin-thank-you': 'optin-thank',
    'webinar-countdown': 'countdown',
    'webinar-room-card': 'webinar',
    'webinar-replay': 'replay',
  },
};
