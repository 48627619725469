<script>
import { mapState } from 'vuex';

export default {
  name: 'EzfCurrency',
  props: ['value'],
  computed: {
    ...mapState(['currency']),
  },
};
</script>

<template>
  <span>{{ value }}{{ currency.symbol }}</span>
</template>
