import Jsonapi from 'jsona';
import getApiAxios from './lib/apiAxios';

const jsonapi = new Jsonapi();

/**
 * Class for manupulate templates with ez-builder API.
 * @module FunnelThemesRepository
 */
class FunnelThemesRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-builder API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = getApiAxios(axiosInstance, { baseURL: `${apiUrl}/v1/` });
  }

  async getCategories(lang) {
    const response = await this.axios.get('categories', {
      params: {
        'page[size]': '100',
        'fields[categories]': `id,name-${lang}`,
      },
    });
    return jsonapi.deserialize(response.data).map((r) => ({
      id: r.id,
      name: r[`name-${lang}`],
    }));
  }

  async getThemes({ lang, funnelType }) {
    const response = await this.axios.get('themes', {
      params: {
        include: 'categories',
        'fields[themes]': `id,cloud-id,name-${lang}`,
        'fields[categories]': 'id',
        'funnel-type': funnelType,
        'check-theme-templates': true,
      },
    });
    return jsonapi.deserialize(response.data);
  }

  async getTheme(themeId, lang) {
    const response = await this.axios.get(`themes/${themeId}`, {
      params: {
        'fields[themes]': `id,cloud-id,name-${lang}`,
      },
    });
    return jsonapi.deserialize(response.data);
  }

  async getTemplates({ /* funnelType, */themeId, stepsTypes }) {
    const response = await this.axios.get('theme-templates', {
      params: {
        'filter[theme-templates.theme][and][id][eq]': themeId,
        'filter[theme-templates][and][step-type][in]': stepsTypes.join(','),
        'page[number]': 1,
        'page[size]': 1000,
      },
    });
    return jsonapi.deserialize(response.data);
  }
}

export default FunnelThemesRepository;
