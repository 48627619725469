import { URL_API_NOTIFICATIONS } from '@dmant/ez-env-common';
import getApiAxios from './lib/apiAxios';

/**
 * Class for manupulate Notifications with Ez Notification API.
 * @module Ez NotificationsRepository
 */
class NotificationsRepository {
  /**
   * @param {object} axios instance
   */
  constructor(axios) {
    this.axios = getApiAxios(axios, { baseURL: `${URL_API_NOTIFICATIONS}/v1/` });
  }

  /**
   * get all webinar notifications by funnel id
   * @param {String} funnelId
   * @param {Number} stepType
   */
  async getWebinarNotifications(funnelId) {
    const response = await this.axios.get('webinar-notifications', {
      params: {
        'filter[webinar-notifications][and][funnelId][eq]': funnelId,
      },
    });
    return response.data;
  }

  /**
   *
   * @param {string} userId
   * @param {Object} Notification
   */
  async createWebinarNotification(userId, attributes) {
    const { data } = await this.axios.post('webinar-notifications', {
      data: {
        attributes,
        relationships: {
          user: {
            data: {
              type: 'user',
              id: userId,
            },
          },
        },
        type: 'webinar-notifications',
      },
    });
    return data;
  }

  async updateWebinarNotification({ id, attributes }) {
    const { data } = await this.axios.patch(`webinar-notifications/${id}`, {
      data: {
        attributes,
        type: 'webinar-notifications',
      },
    });
    return data;
  }

  /**
   *
   * @param {string} webinar-notifications id
   */
  removeWebinarNotifications(id) {
    return this.axios.delete(`webinar-notifications/${id}`);
  }
}

export default NotificationsRepository;
