import Vue from 'vue';

export const SET_FUNNEL = 'SET_FUNNEL';
export const SET_FUNNEL_DOMAIN = 'SET_FUNNEL_DOMAIN';
export const RESET_FUNNEL_CHANGED = 'RESET_FUNNEL_CHANGED';
export const CHANGE_ATTRIBUTE = 'CHANGE_ATTRIBUTE';
export const RESET_ATTRIBUTES = 'RESET_ATTRIBUTES';
export const UNSET_FUNNEL = 'UNSET_FUNNEL';
export const SET_USER = 'SET_USER';
export const SET_FUNNEL_ATTRIBUTE = 'SET_FUNNEL_ATTRIBUTE';

const mutations = {
  [UNSET_FUNNEL]: (state) => {
    state.funnel = null;
    state.funnelChanged = {};
  },
  [SET_FUNNEL]: (state, payload) => {
    state.funnel = payload;
  },
  [SET_USER]: (state, payload) => {
    state.user = payload;
  },
  [SET_FUNNEL_DOMAIN]: (state, payload) => {
    state.funnelDomain = payload;
  },
  [RESET_FUNNEL_CHANGED]: (state) => {
    state.funnelChanged = {};
  },

  [CHANGE_ATTRIBUTE]: (state, { attr, value }) => {
    if (value === state.funnel[attr]) {
      Vue.delete(state.funnelChanged, attr);
    } else {
      Vue.set(state.funnelChanged, attr, {
        value,
      });
    }
  },

  [RESET_ATTRIBUTES]: (state, attrs) => {
    for (const attr of attrs) {
      Vue.delete(state.funnelChanged, attr);
    }
  },

  [SET_FUNNEL_ATTRIBUTE]: (state, { attr, value }) => {
    Vue.set(state.funnel, attr, value);
  },
};

export default mutations;
