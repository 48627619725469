import templatePreview from '@/helpers/templatePreview';
import { GET_FUNNEL, GET_STEPS_NUMS } from '@/store/wizard/getters';

import funnelThemesManager from '@/services/funnelThemesManager';
import cloudManager from '@/services/cloudManager';
import builderManager from '@/services/builderManager';

import * as mutations from './mutations';

export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_THEMES = 'LOAD_THEMES';
export const LOAD_THEME = 'LOAD_THEME';
export const LOAD_TEMPLATES = 'LOAD_TEMPLATES';
export const USE_THEME = 'USE_THEME';

const actions = {
  async [LOAD_CATEGORIES]({ commit, state, rootGetters }) {
    if (state.categories.length) {
      commit(mutations.SET_CATEGORIES, []);
    }
    const result = await funnelThemesManager.getCategories(rootGetters.lang);
    commit(mutations.SET_CATEGORIES, result);
  },
  async [LOAD_THEMES]({ commit, state, rootGetters }) {
    if (state.themes.length) {
      commit(mutations.SET_THEMES, []);
    }
    const result = (await funnelThemesManager.getThemes({
      lang: rootGetters.lang,
      funnelType: rootGetters[`wizard/${GET_FUNNEL}`].num,
    }))
      .map((r) => ({
        // ...r,
        id: r.id,
        name: r[`name-${rootGetters.lang}`],
        'cloud-id': r['cloud-id'],
        categories: r.categories,
      }));

    const ids = result.map((r) => r['cloud-id']).filter(Boolean);
    if (ids.length) {
      const files = await cloudManager.getFiles(ids);
      files.data.forEach((file) => {
        result.forEach((theme) => {
          if (theme['cloud-id'] === file.id) {
            theme.image = file.attributes.url;
          }
        });
      });
    }
    commit(mutations.SET_THEMES, result);
  },
  async [LOAD_THEME]({
    commit, state, rootGetters,
  }, themeId) {
    if (state.theme.id) {
      commit(mutations.SET_THEME, {});
    }
    const { lang } = rootGetters;
    const result = await funnelThemesManager.getTheme(themeId, lang);
    if (result) {
      result.name = result[`name-${rootGetters.lang}`];
      commit(mutations.SET_THEME, result);
    }
  },
  async [LOAD_TEMPLATES]({
    commit, state, rootGetters,
  }, themeId) {
    if (state.templates.length) {
      commit(mutations.SET_TEMPLATES, []);
    }

    const { locale, lang } = rootGetters;
    const query = {
      themeId,
      lang,
      stepsTypes: rootGetters[`wizard/${GET_STEPS_NUMS}`],
      funnelType: rootGetters[`wizard/${GET_FUNNEL}`].num,
    };
    const result = await funnelThemesManager.getTemplates(query);
    const ids = result.map((r) => r['template-id']).filter(Boolean);
    if (ids.length) {
      const templates = await builderManager.getActiveTemplatesByIds(ids);

      for (const template of templates.data) {
        result.forEach((r) => {
          if (r['template-id'] === template.id) {
            r.preview = templatePreview(
              template.attributes,
              500,
              template.attributes && template.attributes['dt-update'],
              locale,
            );
          }
        });
      }
    }

    commit(mutations.SET_TEMPLATES, result.filter((r) => r.preview));
  },
};

export default actions;
