import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function getLocales() {
  if (process.env.VUE_APP_I18N_DEBUG) {
    return require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i);
  }
  return require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
}

function loadLocaleMessages() {
  const locales = getLocales();
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      let localeMessages = locales(key);
      if (process.env.VUE_APP_I18N_DEBUG) {
        localeMessages = localeMessages.default;
      }
      messages[locale] = localeMessages;
    }
  });
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en_EN',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en_EN',
  messages: loadLocaleMessages(),
});
