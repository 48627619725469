/**
 * Class for manupulate groups with ez-funnel API.
 * @module GroupRepository
 */

import axios from 'axios';

function clgError(error) {
  if (error.response) {
    console.log(error.response.data); // eslint-disable-line no-console
    console.log(error.response.status); // eslint-disable-line no-console
    console.log(error.response.headers); // eslint-disable-line no-console
  } else {
    console.log('Error', error.message); // eslint-disable-line no-console
  }
}

function transform2apiFromat(group) {
  const result = {
    data: {
      type: 'groups',
      attributes: {
        name: group.name,
        color: group.color,
      },
      relationships: {
        user: {
          data: {
            type: 'user',
            id: group.userId,
          },
        },
      },
    },
  };
  if (group.id) result.data.attributes.id = group.id;
  return result;
}

export function transform2flatFormat(group) {
  return {
    id: group.id,
    checked: false,
    opened: false,
    name: group.attributes.name,
    color: group.attributes.color,
    index: group.attributes.index,
    funnelCount: group.attributes['funnel-count'],
    dtCreate: group.attributes['dt-create'],
  };
}

class GroupRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-funnel API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = axiosInstance || axios;
    this.apiUrl = apiUrl;
  }

  /**
   * get all groups by user id
   * @param {string} userId id of user
   */
  async getList(userId) {
    let groups = [];
    let response = {};
    try {
      const url = `${this.apiUrl}/v1/groups`;
      const params = {
        'filter[user][eq]': userId,
        sort: 'index,-dt-create',
        'page[size]': 100,
      };
      response = await this.axios.get(url, {
        params,
        withCredentials: true,
      });
    } catch (error) {
      clgError(error);
    }
    if (response.status === 200) {
      groups = response.data.data.map((group) => transform2flatFormat(group));
      // console.log("---", groups);
    }
    return groups;
  }

  /**
   * Create new group
   * @param {object} group attributes of new group
   * @param {string} userId id of user, which own new group
   */
  async create(group, userId) {
    let result = {};
    let response = {};
    try {
      group.userId = userId;
      const newGroup = transform2apiFromat(group);
      response = await this.axios.post(`${this.apiUrl}/v1/groups`, newGroup, {
        withCredentials: true,
      });
    } catch (error) {
      clgError(error);
    }

    if (response.status === 200) {
      result = transform2flatFormat(response.data.data);
    }
    return result;
  }

  /**
   * update existed group
   * @param {string} group group id
   * @param {string} userId id of user, which own group
   */
  async update(group, userId) {
    let result = {};
    let response = {};
    try {
      group.userId = userId;
      const updatedGroup = transform2apiFromat(group);
      response = await this.axios.patch(
        `${this.apiUrl}/v1/groups/${group.id}`,
        updatedGroup,
        { withCredentials: true },
      );
    } catch (error) {
      clgError(error);
    }

    if (response.status === 200) {
      result = transform2flatFormat(response.data.data);
    }
    return result;
  }

  /**
   * delete group by id
   * @param {string} id group id
   */
  async delete(id) {
    let result = null;
    let response = {};
    try {
      response = await this.axios.delete(`${this.apiUrl}/v1/groups/${id}`, {
        withCredentials: true,
      });
    } catch (error) {
      clgError(error);
    }

    if (response.status === 200) {
      result = true;
    }
    return result;
  }

  /**
   * reorder groups
   * @param {array} ids group ids
   */
  async reorder(ids) {
    let groups = false;
    let response = {};
    try {
      response = await this.axios.patch(
        `${this.apiUrl}/v1/groups/move`,
        { data: { id: ids } },
        { withCredentials: true },
      );
    } catch (error) {
      clgError(error);
    }

    if (response.status === 200) {
      groups = response.data.data.map((group) => transform2flatFormat(group));
    }
    return groups;
  }
}

export default GroupRepository;
