import { updateField } from 'vuex-map-fields';
import Vue from 'vue';

export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_THEMES = 'SET_THEMES';
export const SET_THEME = 'SET_THEME';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';

const mutations = {
  updateField,

  [SET_CATEGORIES](state, payload) {
    state.categories = payload;
  },

  [SET_THEMES](state, payload) {
    state.themes = payload;
  },

  [SET_THEME](state, payload) {
    state.theme = payload;
  },

  [SET_TEMPLATES](state, payload) {
    state.templates = payload;
  },

  [SET_SELECTED_TEMPLATE](state, { stepNum, value }) {
    Vue.set(state.selectedTemplates, stepNum, value);
  },
};

export default mutations;
