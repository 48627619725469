export const SET_GEOLOCATION_DATA = 'SET_GEOLOCATION_DATA';
export const SET_WIDGETS_DATA = 'SET_WIDGETS_DATA';
export const SET_REVENUE_DATA = 'SET_REVENUE_DATA';
export const SET_USERS_VIEWS_DATA = 'SET_USERS_VIEWS_DATA';
export const SET_WEBINAR_USERS_VIEWS_DATA = 'SET_WEBINAR_USERS_VIEWS_DATA';
export const SET_STEPS_DATA = 'SET_STEPS_DATA';

const mutations = {
  [SET_GEOLOCATION_DATA]: (state, payload) => {
    state.geolocation = payload;
  },

  [SET_WIDGETS_DATA]: (state, payload) => {
    state.widgets = payload;
  },

  [SET_REVENUE_DATA]: (state, payload) => {
    state.revenue = payload;
  },

  [SET_USERS_VIEWS_DATA]: (state, payload) => {
    state.usersViews = payload;
  },

  [SET_WEBINAR_USERS_VIEWS_DATA]: (state, payload) => {
    state.webinarUsersViews = payload;
  },

  [SET_STEPS_DATA]: (state, { variations, steps }) => {
    state.variations = variations;
    state.steps = steps;
  },
};

export default mutations;
