import notificationsManager from '@/services/notificationsManager';
import * as mutations from './mutations';

export const LOAD = 'LOAD';
export const REMOVE = 'REMOVE';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';

const actions = {
  async [LOAD]({ commit, rootState }) {
    const { data } = await notificationsManager.getWebinarNotifications(rootState.funnel.funnel.id);
    commit(mutations.SET_NOTIFICATIONS, data);
  },
  async [REMOVE]({ commit }, id) {
    await notificationsManager.removeWebinarNotifications(id);
    commit(mutations.REMOVE_NOTIFICATION, id);
  },
  async [CREATE]({ commit, rootState }, { attributes }) {
    const { data } = await notificationsManager.createWebinarNotification(rootState.currentUserId, {
      'funnel-id': rootState.funnel.funnel.id,
      ...attributes,
    });
    commit(mutations.ADD_NOTIFICATION, data);
  },
  async [UPDATE]({ commit }, notification) {
    const { data } = await notificationsManager.updateWebinarNotification(notification);
    commit(mutations.UPDATE_NOTIFICATION, data);
  },
};

export default actions;
