export const WEBINAR = 1;
export const SALESPAGE = 2;
export const OPTIN_SALESPAGE = 3;
export const ORDERFORM = 4;
export const OPTIN = 5;
export const FREE_WEBINAR = 6;
export const FREE_WEBINAR_AFFILIATE = 7;
export const SALESPAGE_AFFILIATE = 8;
export const OPTIN_SALESPAGE_AFFILIATE = 9;
export const WEBINAR_APPLICATIONFORM = 10;
export const FREE_WEBINAR_APPLICATIONFORM = 11;
export const OPTIN_APPLICATIONFORM = 12;
export const APPLICATIONFORM = 13;
