/* eslint-disable no-shadow */
import { groupChartsData } from '@/components/chart/chartHelper';
import { REVENUE_STEPS_TYPES } from '@/enums/stepTypes';

export const GET_WIDGETS_DATA = 'GET_WIDGETS_DATA';
export const GET_USERS_VIEWS_DATA = 'GET_USERS_VIEWS_DATA';
export const GET_WEBINAR_USERS_VIEWS_DATA = 'GET_WEBINAR_USERS_VIEWS_DATA';
export const GET_GEOLOCATION_DATA = 'GET_GEOLOCATION_DATA';
export const GET_REVENUE_STEPS_IDS = 'GET_REVENUE_STEPS_IDS';
export const GET_STEPS = 'GET_STEPS';
export const GET_REVENUE_DATA = 'GET_REVENUE_DATA';

const getters = {
  [GET_WIDGETS_DATA]: (state) => state.widgets,
  [GET_USERS_VIEWS_DATA]: (state) => (part) => groupChartsData(
    state.usersViews,
    null,
    part,
    { date: 'date', value: 'views' },
  ),
  [GET_WEBINAR_USERS_VIEWS_DATA]: (state) => state.webinarUsersViews,
  [GET_GEOLOCATION_DATA]: (state) => state.geolocation,
  [GET_REVENUE_STEPS_IDS]: (state, getters) => getters[GET_STEPS]
    .filter((s) => REVENUE_STEPS_TYPES.includes(s.type))
    .map((s) => s.id),
  [GET_STEPS](state, getters, rootState) { // eslint-disable-line no-shadow
    return rootState.builder.steps.map(({ attributes: { id, type } }) => ({ id, type }));
  },
  [GET_REVENUE_DATA]: (state) => state.revenue,
};

export default getters;
