import webinarsManager from '@/services/webinarsManager';

export const GET_TIMELINE = 'GET_TIMELINE';

const actions = {
  [GET_TIMELINE]: async ({ commit, rootState }, { include } = { include: [] }) => {
    const { funnel } = rootState.funnel;
    /* eslint-disable-next-line */
    const webinar = await webinarsManager.get(funnel.webinarId);
    const timelineItems = await webinarsManager.getTimeLine(funnel.webinarId, include);
    const timeline = [];

    Object.entries(timelineItems).forEach(([type, items]) => {
      items.forEach((item) => {
        item.itemType = type;// === 'CHAT' ? 'live-chats' : type; // hack , FIXME
        timeline.push(item);
      });
    });
    // console.log(timeline);
    // console.log(timeline.map(item => ({
    //   type: item.type,
    //   time: item['time-code'] || item['time-code-start'] || 0,
    //   message: item.message || item.name || item.title,
    // })).sort((a, b) => (a.time - b.time)));
    commit('SET_DURATION', webinar.duration);
    commit('SET_TIMELINE', timeline.map((item) => ({
      type: item.itemType,
      time: item['time-code'] || item['time-code-start'] || 0,
      message: item.message || item.name || item.title,
    })).sort((a, b) => (a.time - b.time)));
    // commit('SORT_TIMELINE');
  },

};

export default actions;
