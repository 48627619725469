<template>
  <div class="app" data-test="app">
    <div class="content">
      <vue-progress-bar />
      <router-view />
      <ModalAuth
        v-if="isExpired"
        service="funnels"
        @success="onSuccessAuth"
      />
      <ez-notice />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ModalAuth } from '@dmant/ez-auth-common';

export default {
  name: 'ezfApp',
  components: {
    ModalAuth,
  },
  computed: mapState(['isExpired']),
  methods: {
    onSuccessAuth() {
      this.$store.commit('UNSET_EXPIRED');
    },
  },
};
</script>

<style lang="scss">
@import "~@dmant/ez-ui-components/src/styles/src/funnels/index.scss";
</style>
