/**
 * Class for manupulate domains with ez-user API.
 * @module DomainRepository
 */

import axios from 'axios';

// const STATUS_NEW = 1;
// const STATUS_PROCEEDING = 2;
const STATUS_VALIDATED = 3;
// const STATUS_VALIDATION_FAILED = 4;
// const STATUS_DELETED = 5;
// const STATUS_UNPAID = 6;

// const SSL_STATUS_NOT = 0;
// const SSL_STATUS_IN_PROGRESS = 1;
// const SSL_STATUS_REQUEST_FAILED = 2;
const SSL_STATUS_ACTIVE = 3;
// const SSL_STATUS_INACTIVE = 4;

function clgError(error) {
  if (error.response) {
    console.log(error.response.data); // eslint-disable-line no-console
    console.log(error.response.status); // eslint-disable-line no-console
    console.log(error.response.headers); // eslint-disable-line no-console
  } else {
    console.log('Error', error.message); // eslint-disable-line no-console
  }
}

export function transform2flatFormat(domain) {
  const { name, type } = domain.attributes;
  const host = domain.attributes['has-www'] ? `www.${name}` : name;
  const withHttps = SSL_STATUS_ACTIVE === domain.attributes['ssl-status'];
  const protocol = withHttps ? 'https:' : 'http:';
  return {
    id: domain.id,
    name,
    host,
    protocol,
    withHttps,
    type,
  };
}

class DomainRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-user API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = axiosInstance || axios;
    this.apiUrl = apiUrl;
  }

  /**
   * get domains by user id
   * @param {string} userId id of user
   */
  async getDomains(userId) {
    let domains = [];
    let response = {};
    const params = {
      'fields[domain]': 'name,type',
      'filter[domain][and][user][eq]': userId,
      'filter[domain][and][status][eq]': STATUS_VALIDATED,
      sort: 'name',
    };
    try {
      response = await this.axios.get(`${this.apiUrl}/v1/domain`, {
        params,
        withCredentials: true,
      });
    } catch (error) {
      clgError(error);
    }
    if (response.status === 200) {
      domains = response.data.data.map((group) => transform2flatFormat(group));
    }
    return domains;
  }

  /**
   * get domain by id
   * @param {string} domainId id of domain
   */
  async getDomain(domainId) {
    const url = `${this.apiUrl}/v1/domain/${domainId}`;
    const params = {
      'fields[domain]': 'id,name,ssl-status,has-www',
    };
    const response = await this.axios.get(url, {
      withCredentials: true,
      params,
    });

    if (response.status === 200) {
      return transform2flatFormat(response.data.data);
    }
    throw new Error('response.status code !== 200');
  }
}

export default DomainRepository;
