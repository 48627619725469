import { URL_PREVIEW } from '@dmant/ez-env-common';
import { getField } from 'vuex-map-fields';
import groupBy from 'lodash/groupBy';
import { GET_STEPS } from '../getters';

export const GET_CATEGORIES_IDS_WITH_THEMES = 'GET_CATEGORIES_IDS_WITH_THEMES';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_TEMPLATE_PREVIEW_URL = 'GET_TEMPLATE_PREVIEW_URL';
export const GET_TEMPLATES_BY_TYPE = 'GET_TEMPLATES_BY_TYPE';
export const GET_STEPS_WITH_TEMPLATES = 'GET_STEPS_WITH_TEMPLATES';
export const GET_SELECTED_TEMPLATE = 'GET_SELECTED_TEMPLATE';

export default {
  getField,

  [GET_CATEGORIES_IDS_WITH_THEMES]: (state) => {
    const ids = new Set();
    state.themes.forEach((t) => t.categories.forEach((c) => ids.add(c.id)));
    return ids;
  },

  [GET_CATEGORIES]: (state, getters) => {
    const ids = getters[GET_CATEGORIES_IDS_WITH_THEMES];
    return state.categories.filter((c) => ids.has(c.id));
  },

  [GET_TEMPLATE_PREVIEW_URL]: (state, getters, rootState) => (templateId) => {
    const { locale } = rootState.mainUser.attributes;
    const lang = locale.substr(0, 2).toLowerCase();
    let result = `${URL_PREVIEW}/${templateId}`;
    if (lang) {
      result += `/${lang.toLowerCase()}`;
    }
    return result;
  },

  [GET_TEMPLATES_BY_TYPE](state) {
    return groupBy(state.templates, 'step-type');
  },

  [GET_STEPS_WITH_TEMPLATES](state, getters, rootState, rootGetters) {
    return rootGetters[`wizard/${GET_STEPS}`].filter((s) => {
      const templates = getters[GET_TEMPLATES_BY_TYPE][s.num] || [];
      return templates.length > 0;
    });
  },

  [GET_SELECTED_TEMPLATE]: (state) => (stepNum) => state.selectedTemplates[stepNum] || '',
};
