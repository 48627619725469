const state = {
  templates: [],
  name: '',
  slug: '',
  locale: '',
  themeId: null,
  domainId: null,
  groupId: null,
};

export default state;
