import funnelsManager from '@/services/funnelsManager';
import { GET_FUNNEL, GET_STEPS_NUMS_ALL } from '@/store/wizard/getters';

export const SUBMIT = 'SUBMIT';

const DEAFULT_OPTIONS = {
  favicon: null,
  headerAlways: '',
  headerGeneral: '',
  headerStart: '',
  headerMarketing: '',
  footerAlways: '',
  footerGeneral: '',
  footerStart: '',
  footerMarketing: '',
  isDsgvo: true,
  dsgvoIsSimple: true,
  archive: false,
  imprint: '',
  privacyPolicy: '',
  domainId: null,
  webinarId: null,
  goal: 1,
  redirectUrl: null,
};

const actions = {
  [SUBMIT]({
    state, rootGetters,
  }, { themeId }) {
    const templates = [...state.templates];
    for (const num of rootGetters[`wizard/${GET_STEPS_NUMS_ALL}`]) {
      const template = process.env[`VUE_APP_FUNNEL_WIZARD_STEP_${num}_TEMPLATE`];
      if (template) {
        templates.push(template);
      }
    }

    return funnelsManager
      .create({
        ...DEAFULT_OPTIONS,
        funnelType: rootGetters[`wizard/${GET_FUNNEL}`].num,
        themeId,
        ...state,
        templates,
      }, rootGetters.currentUserId);
  },
};

export default actions;
