import Vue from 'vue';
import { refreshToken } from '@dmant/ez-auth-common';
import './plugins/sentry';
import VueProgressBar from 'vue-progressbar';
import Notifications from 'vue-notification';
import Ability from '@dmant/ez-lib/src/ability/install';
import QuickStartVideoPlugin from '@dmant/ez-lib/src/quick-start-video';
import VeeValidate from 'vee-validate';
import VueClipboard from 'vue-clipboard2';
import VueMoment from 'vue-moment';
import liveChat from '@dmant/ez-livechat-common';
import EventBus from '@/helpers/eventBus';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import wait from './wait';
import './components';

Vue.use(Notifications);
Vue.use(Ability);
Vue.use(VeeValidate);
Vue.use(VueClipboard);
Vue.use(VueMoment);
Vue.use(liveChat);
Vue.use(QuickStartVideoPlugin, { router, store });

if (!window.EventBus) {
  window.EventBus = new EventBus();
}

const VueProgressBarOptions = {
  color: '#17a2b8',
  failedColor: '#ee5253',
  thickness: '4px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
  location: 'top',
};

Vue.use(VueProgressBar, VueProgressBarOptions);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  i18n,
  wait,
  render: (h) => h(App),
});

app.$mount('#app');

refreshToken();
console.log(`Build: ${BUILD_INFO.BUILDDATETIME}, ${BUILD_INFO.VERSION}, ${BUILD_INFO.COMMITHASH}, ${BUILD_INFO.BRANCH}`);
