/**
 * Class for getting different total statstic width ez-statistics API
 * @module TotalStatisticRepository
 */
import get from 'lodash/get';
import pick from 'lodash/pick';
import { camelCase } from 'change-case';
import getApiAxios from './lib/apiAxios';

function clgError(error) {
  if (error.response) {
    console.log(error.response.data); // eslint-disable-line no-console
    console.log(error.response.status); // eslint-disable-line no-console
    console.log(error.response.headers); // eslint-disable-line no-console
  } else if (error.request) {
    console.log(error.request); // eslint-disable-line no-console
  } else {
    console.log('Error', error.message); // eslint-disable-line no-console
  }
  console.log(error.config); // eslint-disable-line no-console
}

const geolocationFields = [
  'customers',
  'customer-value',
  'lead-gain',
  'leads',
  'revenue',
  // 'sales',
  // 'total-views',
  // 'unique-views',
  'unique-visitors',
  'visitor-value',
];

function transformGeolocation2flatFormat(country) {
  const attributes = get(country, 'attributes', {});
  const field = {};
  for (const [key, value] of Object.entries(pick(attributes, geolocationFields))) {
    field[camelCase(key)] = value;
  }
  return {
    id: country.id,
    ...field,
  };
}

const chartTypeField = {
  'unique-visitors': 'visitors',
  'lead-gain': 'lead-gain',
  'total-leads': 'leads',
  'total-revenue': 'revenue',
  'visitor-value': 'visitor-value',
  'customer-value': 'customer-value',
};

function transformChartsData2flatFormat(type, data) {
  return {
    date: get(data, 'attributes.date', null),
    value: get(data, `attributes.${chartTypeField[type]}`, null),
  };
  // const attributes = get(data, 'attributes', {});
  // const fields = Object.keys(attributes).reduce((result, attr) => {
  //   result[camelCase(attr)] = attributes[attr];
  //   return result;
  // }, {});
  // return fields;
}

class TotalStatisticRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-statistics API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = getApiAxios(axiosInstance, { baseURL: `${apiUrl}/v1/` });
  }

  /**
   * get total statistic by geolocations
   */
  async getGeolocations(userId) {
    const response = await this.axios
      .get('funnel-dashboard/geolocation-total', {
        params: { 'filter[userid][eq]': userId },
      })
      .catch((error) => clgError(error));
    return response && response.data.data
      ? response.data.data.map((country) => transformGeolocation2flatFormat(country))
      : [];
  }

  /**
   * get total statistic for charts
   */
  async getCharts(type, userId, from, to) {
    const response = await this.axios
      .get(`funnel-dashboard/graphs/${type}`, {
        params: {
          'filter[userid][eq]': userId,
          'filter[eventdate][from]': from,
          'filter[eventdate][to]': to,
        },
      })
      .catch((error) => clgError(error));
    return response && response.data.data
      ? response.data.data.map((item) => transformChartsData2flatFormat(type, item))
      : [];
  }
}

export default TotalStatisticRepository;
