import Vue from 'vue';
import Router from 'vue-router';
import { updateAbilityForEntity } from '@dmant/ez-lib/src/ability/update';
import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'checkAccess',
      component: () => import('@dmant/ez-lib/src/ability/checkAccess.vue'),
      meta: { requiresAuth: true },
      props: {
        subject: 'Funnels',
        forbiddenComponent: () => import(/* webpackChunkName: "404" */ '@/pages/403.vue'),
      },
      children: [
        {
          path: '',
          component: () => import('@/pages/layout/layout.vue'),
          children: [
            {
              path: '',
              name: 'dashboard',
              component: () => import('@/pages/dashboard/dashboard.vue'),
            },
            {
              path: 'funnels',
              component: () => import('@/pages/funnels/wrapper.vue'),
              children: [
                {
                  path: '',
                  name: 'funnels',
                  component: () => import(/* webpackChunkName: "funnelsOverview" */ '@/pages/funnels/list.vue'),
                },
                {
                  path: 'archive',
                  name: 'funnelsArchive',
                  component: () => import(/* webpackChunkName: "funnelsOverview" */ '@/pages/funnels/archive.vue'),
                },
                {
                  path: 'groups',
                  name: 'funnelsGroups',
                  component: () => import(/* webpackChunkName: "funnelsOverview" */ '@/pages/funnels/groups.vue'),
                },
              ],
            },
            {
              path: 'create',
              redirect: '/wizard',
              children: [
                { path: '', redirect: '/wizard' },
                { path: 'template', redirect: '/wizard' },
                { path: 'finish', redirect: '/wizard' },
              ],
            },
            {
              path: 'share/:share_hash',
              name: 'share',
              component: () => import(/* webpackChunkName: "funnelShareImport" */ '@/pages/share/import.vue'),
            },
            {
              path: 'funnel/:funnel_id',
              component: () => import(/* webpackChunkName: "funnelEdit" */ '@/pages/funnel/funnel.vue'),
              redirect: 'funnel/:funnel_id/builder',
              async beforeEnter(to, from, next) {
                to.meta.permissions = await updateAbilityForEntity('funnels', 'funnel', to.params.funnel_id);
                if (to.meta.permissions.errors?.[0]?.code === 401) {
                  store.commit('SET_EXPIRED');
                } else {
                  if (!to.meta.permissions.update) {
                    to.meta.accessDenied = true;
                  }
                  next();
                }
              },
              children: [
                {
                  path: 'preview/webinar/replay',
                  name: 'previewWebinarReplay',
                  component: () => import(/* webpackChunkName: "previewWebinarReplay" */ '@/pages/funnel/previewWebinarReplay.vue'),
                },
                {
                  path: 'builder',
                  name: 'funnelBuilder',
                  component: () => import(/* webpackChunkName: "funnelEdit" */ '@/pages/funnel/builder/builder.vue'),
                },
                {
                  path: 'stat',
                  name: 'funnelStatistics',
                  component: () => import(/* webpackChunkName: "funnelEdit" */ '@/pages/funnel/statistics.vue'),
                },
                {
                  path: 'contacts',
                  name: 'funnelContacts',
                  component: () => import(/* webpackChunkName: "funnelEdit" */ '@/pages/funnel/contacts/contacts.vue'),
                },
                {
                  path: 'settings',
                  name: 'funnelSettings',
                  component: () => import(/* webpackChunkName: "funnelEdit" */ '@/pages/funnel/settings/settings.vue'),
                },
              ],
            },
          ],
        },
        {
          path: '/wizard',
          component: () => import(/* webpackChunkName: "wizard" */ '@/pages/wizard/layout.vue'),
          children: [
            {
              path: '',
              name: 'wizard-goal',
              component: () => import(/* webpackChunkName: "wizard" */ '@/pages/wizard/1-type.vue'),
            },
            {
              path: 'funnels',
              name: 'wizard-funnels',
              component: () => import(/* webpackChunkName: "wizard" */ '@/pages/wizard/1-funnels.vue'),
            },
            {
              path: 'funnel/:type',
              component: () => import(/* webpackChunkName: "wizard" */ '@/pages/wizard/funnel.vue'),
              children: [
                {
                  path: 'themes',
                  name: 'wizard-themes',
                  component: () => import(/* webpackChunkName: "wizard" */ '@/pages/wizard/2-themes.vue'),
                },
                {
                  path: 'theme/:theme/templates',
                  name: 'wizard-templates',
                  component: () => import(/* webpackChunkName: "wizard" */ '@/pages/wizard/3-templates.vue'),
                },
                {
                  path: 'theme/:theme/finish',
                  name: 'wizard-finish',
                  component: () => import(/* webpackChunkName: "wizard" */ '@/pages/wizard/4-finish.vue'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '*',
      component: () => import(/* webpackChunkName: "404" */ '@/pages/404.vue'),
      // meta: { liveChat: false },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const isRequire = to.matched.some((record) => record.meta.requiresAuth);
  if (store.getters.isUserSessionValid) {
    next();
  } else {
    await store.dispatch('VALIDATE_SESSION', { isRequire });
    next();
  }
});

router.afterEach((to) => {
  if (to.matched.some((record) => record.meta.liveChat === false)) {
    Vue.prototype.$LiveChat.hide();
  } else {
    Vue.prototype.$LiveChat.show();
  }
});

{
  // eslint-disable-next-line no-underscore-dangle
  const _vue = new Vue();
  router.beforeEach((to, from, next) => {
    _vue.$Progress.start();
    next();
  });
  router.beforeResolve((to, from, next) => {
    _vue.$Progress.set(50);
    next();
  });
  router.afterEach(() => {
    _vue.$Progress.finish();
  });
}

export default router;
