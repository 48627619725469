/**
 * Class for manupulate widgets with ez-funnel API.
 * @module WidgetsRepository
 */
import get from 'lodash/get';
import pick from 'lodash/pick';
import getApiAxios from './lib/apiAxios';

function clgError(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data); // eslint-disable-line no-console
    console.log(error.response.status); // eslint-disable-line no-console
    console.log(error.response.headers); // eslint-disable-line no-console
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request); // eslint-disable-line no-console
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message); // eslint-disable-line no-console
  }
  console.log(error.config); // eslint-disable-line no-console
}

const fields = ['name', 'type', 'value'];
const addUserFields = ['widget-id', 'sorting'];

function transform2flatFormat(widget) {
  const attributes = get(widget, 'attributes', {});
  return {
    id: widget.id,
    ...pick(attributes, fields),
  };
}

function transform2UserflatFormat(widget) {
  const attributes = get(widget, 'attributes', {});
  return {
    id: widget.id,
    ...pick(attributes, [...fields, ...addUserFields]),
  };
}

class WidgetsRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-funnel API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = getApiAxios(axiosInstance, { baseURL: `${apiUrl}/v1/` });
  }

  /**
   * get all widgets
   */
  async getWidgets() {
    const response = await this.axios.get('widgets').catch((error) => clgError(error));
    return response ? response.data.data.map((widget) => transform2flatFormat(widget)) : [];
  }

  /**
   * get user widgets
   */
  async getUserWidgets() {
    const response = await this.axios.get('user-widgets').catch((error) => clgError(error));
    return response ? response.data.data.map((widget) => transform2UserflatFormat(widget)) : [];
  }

  /**
   * attach widget
   * @param {string} widgetId id of widget
   * @param {number} order sorting attribute of widget
   */
  async attachWidget(widgetId, order) {
    const response = await this.axios
      .post('user-widgets/attach', {
        data: {
          type: 'user-widgets-attach',
          attributes: {
            sorting: order,
          },
          relationships: {
            widgets: {
              data: {
                type: 'widgets',
                id: widgetId,
              },
            },
          },
        },
      })
      .catch((error) => clgError(error));
    return get(response, 'data.data', null);
  }

  /**
   * detach widget
   * @param {string} widgetId id of widget
   */
  async detachWidget(widgetId) {
    const response = await this.axios
      .post('user-widgets/detach', {
        data: {
          type: 'user-widgets-detach',
          relationships: {
            widgets: {
              data: {
                type: 'widgets',
                id: widgetId,
              },
            },
          },
        },
      })
      .catch((error) => clgError(error));
    return !!response;
  }

  /**
   * reorder widgets
   * @param {array} widgetIds array ids of widgets
   */
  async reorderWidgets(widgetIds) {
    const data = widgetIds.map((id, index) => ({
      id,
      type: 'user-widgets',
      attributes: {
        sorting: index + 1,
      },
    }));

    const response = await this.axios
      .post('user-widgets/reorder', { data })
      .catch((error) => clgError(error));
    return !!response;
  }
}

export default WidgetsRepository;
