import axios from 'axios';

export default (axiosInstance, config = {}) => {
  const instanceConfig = axiosInstance ? axiosInstance.defaults : {};
  return axios.create({
    ...instanceConfig,
    ...config,
    validateStatus: (status) => status === 200,
    withCredentials: true,
  });
};
