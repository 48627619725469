/**
 * Common global components
 */
import Vue from 'vue';

import Box from '@dmant/ez-ui-components/src/components/box';
import Button from '@dmant/ez-ui-components/src/components/button';
import ButtonGroup from '@dmant/ez-ui-components/src/components/button-group';
import Checkbox from '@dmant/ez-ui-components/src/components/checkbox';
import Col from '@dmant/ez-ui-components/src/components/col';
import Collapse from '@dmant/ez-ui-components/src/components/collapse';
import ColorPicker from '@dmant/ez-ui-components/src/components/color-picker';
import Container from '@dmant/ez-ui-components/src/components/container';
import Content from '@dmant/ez-ui-components/src/components/content';
import Core from '@dmant/ez-ui-components/src/components/core';
import Dialog from '@dmant/ez-ui-components/src/components/dialog';
import Dropdown from '@dmant/ez-ui-components/src/components/dropdown';
import DropdownItem from '@dmant/ez-ui-components/src/components/dropdown-item';
import DropdownMenu from '@dmant/ez-ui-components/src/components/dropdown-menu';
import DropdownUser from '@dmant/ez-ui-components/src/components/dropdown-user';
import FilterControl from '@dmant/ez-ui-components/src/components/filter-control';
import Footer from '@dmant/ez-ui-components/src/components/footer';
import Form from '@dmant/ez-ui-components/src/components/form';
import FormGroup from '@dmant/ez-ui-components/src/components/form-group';
import FormItem from '@dmant/ez-ui-components/src/components/form-item';
import Header from '@dmant/ez-ui-components/src/components/header';
import HeaderBar from '@dmant/ez-ui-components/src/components/header-bar';
import Icon from '@dmant/ez-ui-components/src/components/icon';
import Icons from '@dmant/ez-ui-components/src/components/icons';
import Input from '@dmant/ez-ui-components/src/components/input';
import InfoBox from '@dmant/ez-ui-components/src/components/info-box';
import Layout from '@dmant/ez-ui-components/src/components/layout';
import Link from '@dmant/ez-ui-components/src/components/link';
import Logotype from '@dmant/ez-ui-components/src/components/logotype';
import Main from '@dmant/ez-ui-components/src/components/main';
import Message from '@dmant/ez-ui-components/src/components/message';
import Notification from '@dmant/ez-ui-components/src/components/notification';
import Notice from '@dmant/ez-ui-components/src/components/notice';
import Pagination from '@dmant/ez-ui-components/src/components/pagination';
import Panel from '@dmant/ez-ui-components/src/components/panel';
import Preloader from '@dmant/ez-ui-components/src/components/preloader';
import Radio from '@dmant/ez-ui-components/src/components/radio';
import RichText from '@dmant/ez-ui-components/src/components/rich-text';
import Row from '@dmant/ez-ui-components/src/components/row';
import Scrollable from '@dmant/ez-ui-components/src/components/scrollable';
import Select from '@dmant/ez-ui-components/src/components/select';
import SubHeader from '@dmant/ez-ui-components/src/components/sub-header';
import Steps from '@dmant/ez-ui-components/src/components/steps';
import Switch from '@dmant/ez-ui-components/src/components/switch';
import Table from '@dmant/ez-ui-components/src/components/table';
import TableColumn from '@dmant/ez-ui-components/src/components/table/table-column';
import Tabs from '@dmant/ez-ui-components/src/components/tabs';
import Textarea from '@dmant/ez-ui-components/src/components/textarea';
import TimePicker from '@dmant/ez-ui-components/src/components/time-picker';
import TimePicker2 from '@dmant/ez-ui-components/src/components/time-picker2';
import Tooltip from '@dmant/ez-ui-components/src/components/tooltip';
import { QuickStartVideo } from '@dmant/ez-lib/src/quick-start-video';

import EzfCurrency from './currency';

Vue.component(EzfCurrency.name, EzfCurrency);
Vue.component(QuickStartVideo.name, QuickStartVideo);

Vue.use(Box);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Collapse);
Vue.use(ColorPicker);
Vue.use(Container);
Vue.use(Content);
Vue.use(Core);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(DropdownUser);
Vue.use(FilterControl);
Vue.use(Footer);
Vue.use(Form);
Vue.use(FormGroup);
Vue.use(FormItem);
Vue.use(Header);
Vue.use(HeaderBar);
Vue.use(Icon);
Vue.use(Icons);
Vue.use(Input);
Vue.use(InfoBox);
Vue.use(Layout);
Vue.use(Link);
Vue.use(Logotype);
Vue.use(Main);
Vue.use(Message);
Vue.use(Notification);
Vue.use(Notice);
Vue.use(Pagination);
Vue.use(Panel);
Vue.use(Preloader);
Vue.use(Radio);
Vue.use(RichText);
Vue.use(Row);
Vue.use(Scrollable);
Vue.use(Select);
Vue.use(SubHeader);
Vue.use(Steps);
Vue.use(Switch);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tabs);
Vue.use(Textarea);
Vue.use(TimePicker);
Vue.use(TimePicker2);
Vue.use(Tooltip);
