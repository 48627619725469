export const DEFAULT_CURRENCY = {
  index: 1,
  text: 'EUR',
  symbol: '€',
};
export const ALL_CURRENCY = [
  DEFAULT_CURRENCY,
  {
    index: 2,
    text: 'USD',
    symbol: '$',
  },
  {
    index: 3,
    text: 'CHF',
    symbol: '₣',
  },
];
