export const SET_WIDGETS = 'SET_WIDGETS';
export const SET_USER_WIDGETS = 'SET_USER_WIDGETS';
export const DELETE_USER_WIDGETS_BY_WIDGET_ID = 'DELETE_USER_WIDGETS_BY_WIDGET_ID';
export const SET_TOTAL_GEOLOCATION = 'SET_TOTAL_GEOLOCATION';
export const SET_CHART_DATA = 'SET_CHART_DATA';

const mutations = {
  [SET_WIDGETS]: (state, value) => {
    state.widgets = value;
  },

  [SET_USER_WIDGETS]: (state, value) => {
    state.userWidgets = value;
  },

  [DELETE_USER_WIDGETS_BY_WIDGET_ID]: (state, widgetId) => {
    const index = state.userWidgets.findIndex((w) => w['widget-id'] === widgetId);
    if (index >= 0) state.userWidgets.splice(index, 1);
  },

  [SET_TOTAL_GEOLOCATION]: (state, value) => {
    state.totalGeolocation = value;
  },

  [SET_CHART_DATA]: (state, { value, endPoint }) => {
    state.chartDataType = endPoint;
    state.chartData = value;
  },
};

export default mutations;
