import { URL_PREVIEW, URL_REDIRECT } from '@dmant/ez-env-common';
import { getField } from 'vuex-map-fields';
import router from '@/router';

import { FUNNEL_URL } from '@/store/funnel/getters';

import {
  DEFAULT_STEPS,
  WEBINAR_REPLAY,
  WEBINAR_ROOM_CARD,
  WEBINAR_COUNTDOWN,
} from '@/enums/stepTypes';

export const MISSING_STEPS = 'MISSING_STEPS';
export const IS_INVALID_FUNEL = 'IS_INVALID_FUNEL';
export const NOT_SUPPORTED_FUNNEL_TYPE = 'NOT_SUPPORTED_FUNNEL_TYPE';
export const GET_TEMPLATE_URL = 'GET_TEMPLATE_URL';
export const GET_STEP_URL = 'GET_STEP_URL';
export const HAS_ANY_RRODUCTS = 'HAS_ANY_RRODUCTS';
export const GET_ERRORS = 'GET_ERRORS';
export const GET_VISIBLE_BUTTON_ERROR = 'GET_VISIBLE_BUTTON_ERROR';

export default {
  getField,

  [MISSING_STEPS](state, getters, rootState) {
    const { funnel } = rootState.funnel;
    const { steps } = state;
    const missingSteps = [];
    if (funnel && steps && !getters[NOT_SUPPORTED_FUNNEL_TYPE]) {
      for (const type of DEFAULT_STEPS[funnel.type]) {
        if (!steps.some((step) => type === step.attributes.type)) {
          missingSteps.push(type);
        }
      }
    }
    return missingSteps;
  },
  [NOT_SUPPORTED_FUNNEL_TYPE](state, getters, rootState) {
    const { funnel } = rootState.funnel;
    return funnel && !DEFAULT_STEPS[funnel.type];
  },
  [IS_INVALID_FUNEL](state, getters) {
    if (getters[NOT_SUPPORTED_FUNNEL_TYPE]) {
      return true;
    }
    return getters[MISSING_STEPS].length !== 0;
  },

  [GET_TEMPLATE_URL]: (
    state,
    getters,
    rootState,
  ) => (templateId) => {
    const { funnel } = rootState.funnel;
    let result = `${URL_PREVIEW}/${templateId}`;
    if (funnel.locale) {
      const [lang] = /^\w\w/.exec(funnel.locale);
      if (lang) {
        result += `/${lang.toLowerCase()}`;
      }
    }
    return result;
  },

  [GET_STEP_URL]: (state, getters, rootState, rootGetters) => (step) => {
    const { type } = step.attributes;
    if (WEBINAR_COUNTDOWN === Number(type)) {
      if (step.variations[0] && step.variations[0].template) {
        const { id } = step.variations[0].template;
        return `${URL_PREVIEW}/${id}`;
      }
      return '';
    }

    if (WEBINAR_ROOM_CARD === Number(type)) {
      const { funnel } = rootState.funnel;
      return `${URL_REDIRECT}/rw/${funnel.id}/${funnel.webinarId}/now`;
    }

    if (WEBINAR_REPLAY === Number(type)) {
      return router.resolve({ name: 'previewWebinarReplay' }).href;
    }

    const { slug } = step.attributes;
    const funnelUrl = rootGetters[`funnel/${FUNNEL_URL}`];
    return `${funnelUrl}/${slug}`;
  },

  [HAS_ANY_RRODUCTS](state) {
    return state.steps.some((step) => step.product);
  },
  [GET_ERRORS](state) {
    return state.errors.map((item) => {
      if (item.type === 'step') {
        return {
          ...item,
          step: state.steps.find((s) => s.attributes.id === item.id),
        };
      }
      return item;
    });
  },
  [GET_VISIBLE_BUTTON_ERROR](state) {
    return state.isErrorButtonVisible;
  },
};
