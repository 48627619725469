// import Vue from "vue";

export const SET_HEADER_TITLE = 'SET_HEADER_TITLE';
// export const SET_HEADER_TABS_VISIBILITY = "SET_HEADER_TABS_VISIBILITY";
export const SET_HEADER_TABS = 'SET_HEADER_TABS';
// export const SET_HEADER_CREATE_FUNNEL_VISIBILITY =
//   "SET_HEADER_CREATE_FUNNEL_VISIBILITY";
// export const MODIFY_NAVTAB_BY_ID = "MODIFY_NAVTAB_BY_ID";
export const SET_ACTIVE_NAV_LINK = 'SET_ACTIVE_NAV_LINK';
export const SET_ACTIVE_NAV_TAB = 'SET_ACTIVE_NAV_TAB';
export const SET_SERVICE_LIMITS = 'SET_SERVICE_LIMITS';
export const SET_TARIFF = 'SET_TARIFF';

const mutations = {
  [SET_HEADER_TITLE]: (state, value) => {
    state.title = value;
  },
  // [SET_HEADER_TABS_VISIBILITY]: (state, value) => {
  //   state.isShowNavTabs = value;
  // },
  [SET_HEADER_TABS]: (state, value) => {
    state.navtabLinks = value;
  },
  // [SET_HEADER_CREATE_FUNNEL_VISIBILITY]: (state, value) => {
  //   state.isShowCreateFunnelButton = value;
  // },
  // [MODIFY_NAVTAB_BY_ID]: (state, { id, field, value }) => {
  //   let navTab = state.navtabLinks.find(v => v.id === id);
  //   if (navTab) Vue.set(navTab, field, value);
  // },
  [SET_ACTIVE_NAV_LINK]: (state, value) => {
    state.activeNavbarLink = value;
  },
  [SET_ACTIVE_NAV_TAB]: (state, id) => {
    state.activeNavTabLink = id;
  },
  [SET_SERVICE_LIMITS](state, limits) {
    state.limits = limits;
  },
  [SET_TARIFF](state, tariff) {
    state.tariff = tariff;
  },
};

export default mutations;
