import axios from 'axios';
import { goToSsoPage } from './apiUser';
import store from '../store';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('response.status in error = ', error); // eslint-disable-line no-console
    const { status, url } = error.response || {};
    if (status === 403) {
      if (!url.endsWith('/identity')) {
        store.commit('SET_EXPIRED');
      }
    } else if (status === 401) {
      goToSsoPage();
    }
    // Do something with response error
    return Promise.reject(error);
  },
);

export default axios;
