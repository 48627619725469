export const DIALOG_SPLIT_TEST = 'DIALOG_SPLIT_TEST';

const state = {
  init: null,
  webinarBoxExpanded: false,
  steps: null,
  archiveLength: null,
  archiveVariationLength: null,
  dialogStep: null,
  paymentProviderId: null,
  productList: null,
  [DIALOG_SPLIT_TEST]: false,
  errors: [],
  isErrorButtonVisible: false,
};

export default state;
