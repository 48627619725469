import { getStepTypeByNum } from '@/enums/stepTypes';
import funnelStatisticManager from '@/services/funnelStatisticManager';
import webinarsManager from '@/services/webinarsManager';
import { GET_REVENUE_STEPS_IDS, GET_STEPS } from './getters';
import * as mutations from './mutations';

export const LOAD_GEOLOCATION_DATA = 'LOAD_GEOLOCATION_DATA';
export const LOAD_WIDGETS_DATA = 'LOAD_WIDGETS_DATA';
export const LOAD_REVENUE_DATA = 'LOAD_REVENUE_DATA';
export const LOAD_USERS_VIEWS_DATA = 'LOAD_USERS_VIEWS_DATA';
export const LOAD_WEBINAR_USERS_VIEWS_DATA = 'LOAD_WEBINAR_USERS_VIEWS_DATA';
export const LOAD_STEPS_DATA = 'LOAD_STEPS_DATA';

const actions = {
  [LOAD_GEOLOCATION_DATA]: async ({ commit, rootState }) => {
    const { funnel } = rootState.funnel;
    const geoData = await funnelStatisticManager.getGeoData(funnel.revisionId);
    commit(mutations.SET_GEOLOCATION_DATA, geoData);
  },

  [LOAD_WIDGETS_DATA]: async ({ commit, rootState }) => {
    const { funnel } = rootState.funnel;
    const [widgetsData, devicesData] = await Promise.all([
      funnelStatisticManager.getWidgetsData(funnel.revisionId),
      funnelStatisticManager.getDevicesData(funnel.revisionId),
    ]);
    const data = {
      ...widgetsData,
      ...devicesData,
    };
    commit(mutations.SET_WIDGETS_DATA, data);
  },

  [LOAD_REVENUE_DATA]: async ({ commit, rootState, getters }) => {
    let geoData = [];
    const { funnel } = rootState.funnel;
    const stepsIds = getters[GET_REVENUE_STEPS_IDS];
    if (stepsIds.length) {
      const params = {
        revisionId: funnel.revisionId,
        stepsIds,
      };
      geoData = await funnelStatisticManager.getRevenueData(params);
      geoData = geoData.map((r) => {
        const { leads, revenue, sales } = r;
        const step = rootState.builder.steps.find((s) => s.attributes.id === r.id);
        return {
          name: step ? step.attributes.name : '',
          type: step ? getStepTypeByNum(step.attributes.type).title : '',
          leads,
          revenue,
          sales,
        };
      });
    }
    commit(mutations.SET_REVENUE_DATA, geoData);
  },

  [LOAD_USERS_VIEWS_DATA]: async ({ commit, rootState }) => {
    const { funnel } = rootState.funnel;
    const geoData = await funnelStatisticManager.getUsersPageViewsData(funnel.revisionId);
    commit(mutations.SET_USERS_VIEWS_DATA, geoData);
  },

  [LOAD_WEBINAR_USERS_VIEWS_DATA]: async ({ commit, rootState }) => {
    const { funnel } = rootState.funnel;
    const graphData = await webinarsManager.getUsersWebinarPageViewsData({
      webinarId: funnel.webinarId,
      funnelId: funnel.id,
    });
    commit(mutations.SET_WEBINAR_USERS_VIEWS_DATA, graphData);
  },

  [LOAD_STEPS_DATA]: async ({ commit, rootState, getters }) => {
    const { funnel } = rootState.funnel;
    const params = {
      revisionId: funnel.revisionId,
      steps: getters[GET_STEPS],
    };
    const { variations, steps: stepsStatistic } = await funnelStatisticManager.getStepsData(params);
    const steps = stepsStatistic.map((step) => {
      const { id, stepType, ...stats } = step;
      delete stats.stepId;

      const funnelStep = rootState.builder.steps.find((s) => s.attributes.id === id);
      return {
        id,
        name: funnelStep ? funnelStep.attributes.name : '',
        typeId: stepType,
        // import { getStepTypeByNum } from '@/enums/stepTypes';
        // typeTitle: getStepTypeByNum(stepType).title,
        stats,
      };
    });
    commit(mutations.SET_STEPS_DATA, { variations, steps });
  },
};

export default actions;
