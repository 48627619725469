import { URL_CONTACTS } from '@dmant/ez-env-common';

const state = {
  title: [],
  navbarLinks: [
    {
      id: 'dashboard',
      icon: 'tachometer',
      name: 'navbar.dashboard',
      route: '/',
      // isRouterLink: true,
      testAttr: 'dashboard',
    },
    {
      id: 'funnels',
      icon: 'filter',
      name: 'navbar.funnels',
      route: '/funnels',
      // isRouterLink: true,
      testAttr: 'funnel-overview',
    },
    // { id: "pages", icon: "browser", name: "navbar.pages", route: "/" },
    {
      id: 'contacts',
      icon: 'id-card',
      name: 'navbar.contacts',
      // target: '_blank',
      route: URL_CONTACTS,
      testAttr: 'contacts',
    },
  ],
  activeNavbarLink: 'dashboard',
  limits: {},
  tariff: {},
  // isShowCreateFunnelButton: false,
  // isShowNavTabs: false,
  navtabLinks: [],
  activeNavTabLink: null,
};

export default state;
