import getApiAxios from './lib/apiAxios';

/**
 * Class for manupulate templates with ez-builder API.
 * @module BuilderRepository
 */
class BuilderRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-builder API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = getApiAxios(axiosInstance, { baseURL: `${apiUrl}/v1/` });
  }

  /**
   * get template by id
   * @param {String} id
   */
  async getTemplateById(id) {
    const { data } = await this.axios.get(`templates/${id}`);
    return data;
  }

  /**
   * get templates by type
   *
   * @param {*} query
   * @param {*} page and limit
   */
  async getTemplatesByType({ userId, ...query }, { page = 1, limit } = {}) {
    const params = {
      'page[number]': page,
      'page[size]': limit,
      'filter[templates][and][user][eq]': userId,
    };
    for (const [key, value] of Object.entries(query)) {
      params[`filter[templates][and][${key}][eq]`] = value;
    }
    const response = await this.axios.get('templates', {
      params,
    });
    return response.data;
  }

  /**
   * get templates by ids
   * @param {Array} ids
   */
  async getTemplatesByIds(ids) {
    const { data } = await this.axios.get('templates', {
      params: {
        'filter[templates][and][id][in]': ids.join(','),
        'fields[templates]': 'id,name-en,name-de,dt-update,preview-cloud,preview-de-cloud,preview-en-cloud',
      },
    });
    return data;
  }

  /**
   * get templates by ids
   * @param {Array} ids
   */
  async getActiveTemplatesByIds(ids) {
    const { data } = await this.axios.get('templates', {
      params: {
        'filter[templates][and][status][eq]': 1,
        'filter[templates][and][id][in]': ids.join(','),
        'fields[templates]': 'id,name-en,name-de,dt-update,preview-cloud,preview-de-cloud,preview-en-cloud',
        'page[number]': 1,
        'page[size]': 1000,
      },
    });
    return data;
  }
}

export default BuilderRepository;
