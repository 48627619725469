export default {
  currentUserId: (state) => state.currentUserId,
  locale(state) {
    if (state.mainUser) {
      return state.mainUser.attributes.locale;
    }
  },
  lang(state, getters) {
    const { locale } = getters;
    if (locale) {
      return locale.substr(0, 2).toLowerCase();
    }
  },
  mainUserLocale: (state) => (state.mainUser ? state.mainUser.attributes.locale : ''),
  isUserSessionValid: (state) => state.isAuthorized,
  availableUsersPrettyFormat(state) {
    const fullUsersListPretty = state.availableUsers.map((u) => {
      const userInPrettyFormat = {

        id: u.id,
        name: `${u.attributes['first-name']} ${u.attributes['last-name']}`,
        email: u.attributes.email,
        image: state.avatarUrls[u.attributes.avatar] || '',
      };
      return userInPrettyFormat;
    });
    return fullUsersListPretty;
  },
};
