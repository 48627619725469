import { groupChartsData } from '@/components/chart/chartHelper';

export const GET_WIDGETS = 'GET_WIDGETS';
export const GET_USER_WIDGETS = 'GET_USER_WIDGETS';
export const GET_TOTAL_GEOLOCATION = 'GET_TOTAL_GEOLOCATION';
export const GET_CHART_DATA = 'GET_CHART_DATA';

const getters = {
  [GET_WIDGETS]: (state) => state.widgets,
  [GET_USER_WIDGETS]: (state) => state.userWidgets,
  [GET_TOTAL_GEOLOCATION]: (state) => state.totalGeolocation,
  [GET_CHART_DATA](state) {
    return (part) => groupChartsData(state.chartData, state.chartDataType, part);
  },
};

export default getters;
