import max from 'lodash/max';
import widgetsDemoData from '@/demo-data/dashboardWidgets.json';
import chartsDemoData from '@/demo-data/dashboardCharts.json';
import geolocationDemoData from '@/demo-data/dashboardGeolocation.json';

import widgetsManager from '@/services/widgetsManager';
import totalStatisticManager from '@/services/totalStatisticManager';
import * as mutations from './mutations';

export const LOAD_WIDGETS = 'LOAD_WIDGETS';
export const LOAD_USER_WIDGETS = 'LOAD_USER_WIDGETS';
export const CHANGE_WIDGETS = 'CHANGE_WIDGETS';
export const LOAD_TOTAL_GEOLOCATION = 'LOAD_TOTAL_GEOLOCATION';
export const LOAD_CHART_DATA = 'LOAD_CHART_DATA';

const actions = {
  [LOAD_WIDGETS]: async ({ commit }, demo) => {
    const [widgets, userWidgets] = !demo
      ? await Promise.all([widgetsManager.getWidgets(), widgetsManager.getUserWidgets()])
      : [widgetsDemoData.widgets, widgetsDemoData.user_widgets];
    commit(mutations.SET_WIDGETS, widgets);
    commit(mutations.SET_USER_WIDGETS, userWidgets);
  },

  [LOAD_USER_WIDGETS]: async ({ commit }) => {
    const userWidgets = await widgetsManager.getUserWidgets();
    commit(mutations.SET_USER_WIDGETS, userWidgets);
  },

  [CHANGE_WIDGETS]: async ({ state, /* commit, */ dispatch }, { attach, detach, order }) => {
    // console.log({ attach, detach, order });
    if (detach || attach) {
      const promises = [];
      if (detach) detach.forEach((id) => promises.push(widgetsManager.detachWidget(id)));
      if (attach) {
        const maxOrder = max(
          state.userWidgets
            .filter((w) => order.includes(w['widget-id']))
            .map((w) => w.sorting),
        ) || 0;
        attach.forEach((id, index) => {
          promises.push(widgetsManager.attachWidget(id, maxOrder + index + 1));
        });
      }

      // вернуть после починки attach
      // const resultApi = await Promise.all(promises);

      // if (detach) {
      //   detach.map((id) => {
      //     if (resultApi.shift()) commit(mutations.DELETE_USER_WIDGETS_BY_WIDGET_ID, id);
      //   });
      // }

      // временно, пока attach не будет возвращать созданный объект,
      // запрашиваем все виджеты пользователя.
      // После просто добавить объект в state.userWidgets
      await Promise.all(promises);
      await dispatch(LOAD_USER_WIDGETS);
    } else if (order) {
      const newUserOrders = order.map((id) => state.userWidgets.find((w) => w['widget-id'] === id).id);
      if (await widgetsManager.reorderWidgets(newUserOrders)) await dispatch(LOAD_USER_WIDGETS);
    }
  },

  [LOAD_TOTAL_GEOLOCATION]: async ({ commit, rootState }, demo) => {
    const statistics = !demo
      ? await totalStatisticManager.getGeolocations(rootState.currentUserId)
      : geolocationDemoData;
    commit(mutations.SET_TOTAL_GEOLOCATION, statistics);
  },

  [LOAD_CHART_DATA]: async ({ commit, rootState }, { endPoint, demo }) => {
    const dateFrom = Date.UTC(2019, 3, 1);
    const dateTo = Date.now();
    const statistics = !demo
      ? await totalStatisticManager.getCharts(endPoint, rootState.currentUserId, dateFrom, dateTo)
      : chartsDemoData[endPoint];
    commit(mutations.SET_CHART_DATA, { value: statistics, endPoint });
  },
};

export default actions;
