import { URL_API_FUNNELS } from '@dmant/ez-env-common';
import getApiAxios from './lib/apiAxios';

function getError(error) {
  const result = {
    error: true,
    message: '',
    data: undefined,
    attributes: {},
  };
  if (error.response) {
    result.data = error.response.data;
    if (result.data.errors) {
      result.message = result.data.errors
        .reduce((row, err) => {
          const msg = err.detail || err.title;
          if (!row.includes(msg)) row.push(msg);
          const { pointer } = err.source;
          const PREFIX = '/data/attribute/';
          if (pointer.startsWith(PREFIX)) {
            const field = pointer.substr(PREFIX.length);
            result.attributes[field] = msg;
          }
          return row;
        }, [])
        .join('; ');
    } else result.message = 'ERROR!';
  } else {
    result.message = error.message;
  }
  throw result;
}

/**
 * Class for manupulate funnel steps with ez-funnel API.
 * @module StepsRepository
 */
class StepsRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   */
  constructor(axiosInstance) {
    this.axios = getApiAxios(axiosInstance, { baseURL: `${URL_API_FUNNELS}/v1/` });
  }

  async load(funnel) {
    const params = {
      'filter[steps.funnel][and][id][eq]': funnel.id,
      include: 'variations,fallback,products,actions',
    };
    const response = await this.axios.get('steps/', { params });
    const steps = [];
    const { data, included } = response.data;

    function getIncluded(d) {
      return d
        .map((props) => included.find((row) => row.type === props.type && row.id === props.id));
    }

    const productsIds = [];
    for (const dataRow of data) {
      const { relationships } = dataRow;
      const [product] = getIncluded(relationships.products.data);
      if (product) productsIds.push(product.id);
    }
    const productsWithName = await this.loadProducts(productsIds);

    for (const dataRow of data) {
      const { id, attributes, relationships } = dataRow;
      // variations && archive
      const variations = [];
      const archive = [];
      for (const variation of getIncluded(relationships.variations.data)) {
        if (variation.attributes.archive) {
          archive.push(variation);
        } else {
          variations.push(variation);
        }
      }
      const actions = getIncluded(relationships.actions.data);
      const [fallback] = relationships.fallback.data.map((props) => props.id);
      const [product] = getIncluded(relationships.products.data);

      const existingProduct = productsWithName.find((p) => p && product && p.id === product.id);
      if (existingProduct) {
        product.attributes.name = existingProduct.attributes?.name;
        product.status = existingProduct.status;
      }

      const step = {
        attributes: { id, ...attributes },
        actions,
        fallback, // step id or undefined
        product,
        variations: variations.sort(
          (a, b) => a.attributes.index - b.attributes.index,
        ),
        archive: archive.sort((a, b) => a.attributes.index - b.attributes.index),
      };
      steps.push(step);
    }
    return steps.sort((a, b) => a.attributes.lft - b.attributes.lft);
  }

  async loadProducts(productsIds) {
    if (!productsIds.length) return [];
    const params = {
      'filter[id][in]': productsIds.join(','),
      'add-product-attribute': true,
    };
    const response = await this.axios.get('products/', { params });
    const { data } = response.data;
    return data;
  }

  async createStepProduct({ userId, stepId, product }) {
    const { data } = await this.axios.post('products', {
      data: {
        attributes: product.attributes,
        relationships: {
          step: {
            data: {
              type: 'steps',
              id: stepId,
            },
          },
          user: {
            data: {
              type: 'user',
              id: userId,
            },
          },
        },
        type: 'products',
      },
    });
    return data;
  }

  async saveStepProduct(productId, attributes) {
    const response = await this.axios.patch(`products/${productId}`, {
      data: {
        attributes,
        type: 'products',
      },
    });
    return response.data;
  }

  async deleteStepProduct(productId) {
    const response = await this.axios.delete(`products/${productId}`);
    return response.data;
  }

  async createStep({
    step, userId, funnelId, meta,
  }) {
    const { attributes } = step;
    const { data } = await this.axios.post('steps', {
      data: {
        attributes,
        meta,
        relationships: {
          user: {
            data: {
              type: 'user',
              id: userId,
            },
          },
          funnel: {
            data: {
              type: 'funnel',
              id: funnelId,
            },
          },
        },
        type: 'steps',
      },
    });
    return data;
  }

  async saveStepAttributes(stepId, attributes) {
    const { data } = await this.axios.patch(`steps/${stepId}`, {
      data: {
        attributes,
        type: 'steps',
      },
    }).catch((error) => getError(error));
    return data;
  }

  async saveStepFallback(stepId, fallbackId) {
    const { data } = await this.axios.patch(`steps/${stepId}`, {
      data: {
        relationships: {
          fallback: {
            data: {
              type: 'fallback',
              id: fallbackId,
            },
          },
        },
        type: 'steps',
      },
    });
    return data;
  }

  async deleteStep(step) {
    const { data } = await this.axios.delete(`steps/${step.attributes.id}`);
    return data;
  }

  async moveStepBefore(stepId, moveBeforeStepId) {
    const { data } = await this.axios.get(
      `steps/${stepId}/move-as-prev-sibling-of/${moveBeforeStepId}`,
    );
    return data;
  }

  async createVariation({
    stepId, templateId, userId, funnelId, percent,
  }) {
    const { data } = await this.axios.post('variations', {
      data: {
        attributes: {
          // main: false,
          // archive: false,
          'template-id': templateId,
          percent,
        },
        relationships: {
          user: {
            data: {
              type: 'user',
              id: userId,
            },
          },
          step: {
            data: {
              type: 'step',
              id: stepId,
            },
          },
          funnel: {
            data: {
              type: 'funnel',
              id: funnelId,
            },
          },
        },
        type: 'variations',
      },
    });
    return data;
  }

  async changeVariationAttributes(variationId, attributes) {
    const { data } = await this.axios.patch(`variations/${variationId}`, {
      data: {
        attributes,
      },
    });
    return data;
  }

  async setVariationsPercent(data) {
    const response = await this.axios.patch('variations/percent', {
      data,
    });
    return response.data;
  }
}

export default StepsRepository;
