import moment from 'moment-timezone';
import { getField } from 'vuex-map-fields';
import i18n from '@/i18n';
import Fuse from 'fuse.js';

export const ALL_GROUPS = 'ALL_GROUPS';
export const COUNT_GROUPS = 'COUNT_GROUPS';
export const GROUPS = 'GROUPS';
export const GROUPS_FUSE_SEARCH = 'GROUPS_FUSE_SEARCH';
export const ALL_FUNNELS = 'ALL_FUNNELS';
export const FUNNELS = 'FUNNELS';
export const LATEST_FUNNELS = 'LATEST_FUNNELS';
export const FUNNELS_FUSE_SEARCH = 'FUNNELS_FUSE_SEARCH';
export const FUNNELS_COUNT = 'FUNNELS_COUNT';
export const FUNNELS_GROUP = 'FUNNELS_GROUP';
export const ARCHIVE_FUNNELS_COUNT = 'ARCHIVE_FUNNELS_COUNT';
export const ALL_FUNNELS_COUNT = 'ALL_FUNNELS_COUNT';
export const SELECTED_FUNNELS = 'SELECTED_FUNNELS';
export const COUNT_SELECTED_FUNNELS = 'COUNT_SELECTED_FUNNELS';
export const HAS_SELECTED_FUNNELS = 'HAS_SELECTED_FUNNELS';
export const PAGINATION = 'PAGINATION';
export const GET_HEADER_TABS = 'GET_HEADER_TABS';

function normalizeGermanChars(str) {
  const map = {
    ä: 'ae', ö: 'oe', ü: 'ue', ß: 'ss',
  };

  return str.replace(/[äöüß]/g, (match) => map[match]).toLowerCase();
}

export default {
  getField,

  [ALL_GROUPS](state) {
    return state.groups.filter((group) => group.id !== 'ungrouped');
  },

  [COUNT_GROUPS](state, getters) {
    return getters[ALL_GROUPS].length;
  },
  normalizedSearch(state) {
    return normalizeGermanChars(state.search) || '';
  },
  normalizedGroups(state, getters) {
    const result = getters[ALL_GROUPS].map((item) => ({
      ...item,
      searchName: normalizeGermanChars(item.name),
      searchType: normalizeGermanChars(item.type),
    }));

    return result || [];
  },

  [GROUPS_FUSE_SEARCH](state, getters) {
    const options = {
      shouldSort: true,
      threshold: 0.25,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1, // keys: ["name"]
      keys: [{
        name: 'searchName', weight: 0.9,
      }],
    };
    return new Fuse(getters['funnels/normalizedGroups'], options);
  },

  [GROUPS](state, getters) {
    if (state.search === '') {
      return getters[ALL_GROUPS];
    }

    return (
      getters[GROUPS_FUSE_SEARCH].search(getters.normalizedSearch).map(({ item }) => item) || []
    );
  },

  [ALL_FUNNELS]: (state, getters, rootState) => {
    const locale = rootState.mainUser ? rootState.mainUser.attributes.locale.replace('_', '-') : 'de-DE';
    return state.funnels.map((f) => {
      const updatedFunnel = { ...f };
      updatedFunnel.dtUpdateAgo = moment(f.dtUpdate)
        .locale(locale)
        .fromNow();
      return updatedFunnel;
    });
  },

  [LATEST_FUNNELS]: (state) => state.latestFunnels,

  normalizedFunnels(state, getters) {
    const result = getters[ALL_FUNNELS].map((item) => ({
      ...item,
      searchName: normalizeGermanChars(item.name),
      searchType: normalizeGermanChars(item.type),
      searchTypeDe: normalizeGermanChars(i18n.t(`funnels.types.${item.type}`)),
    }));

    return result || [];
  },

  [FUNNELS_FUSE_SEARCH]: (state, getters) => {
    const options = {
      shouldSort: true,
      threshold: 0.25,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1, // keys: ["name"]
      keys: [
        {
          name: 'searchName', weight: 0.9,
        },
        {
          name: 'searchTypeDe', weight: 0.1,
        },
        {
          name: 'searchType', weight: 1,
        },
      ],
    };
    return new Fuse(getters.normalizedFunnels, options);
  },

  [FUNNELS]: (state, getters) => {
    if (state.search === '') {
      return getters[ALL_FUNNELS];
    }
    return getters[FUNNELS_FUSE_SEARCH].search(getters.normalizedSearch).map(({ item }) => item);
  },

  [FUNNELS_COUNT]: (state) => state.funnels.length,

  [ARCHIVE_FUNNELS_COUNT]: (state) => state.archiveCount,

  [ALL_FUNNELS_COUNT]: (state) => state.allCount,

  [FUNNELS_GROUP](state, getters) {
    const funnelsGroupIds = new Set();
    for (const funnel of getters[FUNNELS]) {
      funnelsGroupIds.add(funnel.groupId);
    }
    return state.groups.filter((g) => funnelsGroupIds.has(g.id));
  },

  [SELECTED_FUNNELS](state, getters) {
    return getters[FUNNELS].filter((f) => f.checked).map((f) => f.id);
  },

  [COUNT_SELECTED_FUNNELS](state, getters) {
    return getters[SELECTED_FUNNELS].length;
  },

  [HAS_SELECTED_FUNNELS](state, getters) {
    return getters[COUNT_SELECTED_FUNNELS] > 0;
  },

  [PAGINATION]: (state) => state.pagination,

  [GET_HEADER_TABS]: (state) => {
    const navtabLinks = [];
    navtabLinks.push({
      id: 'funnels', // route name
      name: 'funnels.tabs.allFunnels',
      route: '/funnels',
      params: 'build',
      active: false,
    });
    if (state.archiveCount > 0) {
      navtabLinks.push({
        id: 'funnelsArchive', // route name
        name: 'funnels.tabs.archive',
        route: '/funnels/archive',
        params: 'statistics',
        active: false,
      });
    }
    // navtabLinks.push({
    //   id: 'funnelsGroups', // route name
    //   name: 'funnels.tabs.groups',
    //   route: '/funnels/groups',
    //   params: 'contacts',
    //   active: false,
    // });
    return navtabLinks;
  },
};
