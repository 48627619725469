/**
 * Class for manupulate users with ez-user API.
 * @module UserRepository
 */

import axios from 'axios';
import get from 'lodash/get';
import set from 'lodash/set';
import times from 'lodash/times';
import flatten from 'lodash/flatten';

function clgError(error) {
  if (error.response) {
    console.log(error.response.data); // eslint-disable-line no-console
    console.log(error.response.status); // eslint-disable-line no-console
    console.log(error.response.headers); // eslint-disable-line no-console
  } else {
    console.log('Error', error.message); // eslint-disable-line no-console
  }
}

const ATTRIBUTES_MAP = {
  firstNname: 'first-name',
  lastName: 'last-name',
  email: 'email',
  slug: 'slug',
  locale: 'locale',
  timeZone: 'time-zone',
  currency: 'currency',
  country: 'country',
  city: 'city',
  addr: 'addr',
  state: 'state',
  zip: 'zip',
  phone: 'phone',
  avatar: 'avatar',
  contract: 'contract',
  customerTypeDivision: 'customer-type-division',
  hold: 'hold',
  lock: 'lock',
  terms: 'terms',
  showWidget: 'show-widget',
  imprint: 'imprint',
  privacyPolicy: 'privacy-policy',
  affiliate: 'affiliate',
  veryficationCode: 'veryfication-code',
  apiKey: 'api-key',
  dtCreateVeryficationCode: 'dt-create-veryfication-code',
  dtCreate: 'dt-create',
  dtUpdate: 'dt-update',
};

function mapAttributes(attributes) {
  const user = {};
  for (const [key, apiAttr] of Object.entries(ATTRIBUTES_MAP)) {
    const attr = attributes[apiAttr];
    if (attr) user[key] = attr;
  }
  return user;
}

export function transform2flatFormat({ id, attributes }) {
  return {
    id,
    ...mapAttributes(attributes),
  };
}

class UserRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-user API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = axiosInstance || axios;
    this.apiUrl = apiUrl;
  }

  /**
   * get user by user id
   * @param {string} userId id of user
   */
  async getUser(userId, params = {}, raw = false) {
    let response = {};
    try {
      response = await this.axios.get(
        `${this.apiUrl}/v1/users/${userId}`,
        {
          params,
          withCredentials: true,
        },
      );
    } catch (error) {
      clgError(error);
    }
    if (response.status === 200) {
      if (raw) {
        return response.data.data;
      }
      return transform2flatFormat(response.data.data);
    }
    return null;
  }

  /**
   * get user attribtes by user id
   * @param {string} userId id of user
   * @param {array} fields list of attributes
   */
  async getUserAttributes(userId, fields) {
    const attributes = fields.map((f) => ATTRIBUTES_MAP[f]).join(',');
    return this.getUser(userId, {
      'fields[users]': attributes,
    });
  }

  async getDefaultDomain(userId) {
    const { slug } = await this.getUserAttributes(userId, ['slug']);
    return slug;
  }

  async getSMTPList(userId) {
    const response = await this.axios.get(`${this.apiUrl}/v1/smtp`, {
      params: {
        'filter[smtp][and][user][eq]': userId,
        'filter[smtp][and][active][eq]': 1,
        sort: 'name',
      },
      withCredentials: true,
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('response.status code !== 200');
  }

  async getPaymentList(userId) {
    const response = await this.axios.get(`${this.apiUrl}/v1/payment`, {
      params: {
        'filter[payment][and][user][eq]': userId,
        'fields[payment]': 'name,type,orderform-id',
      },
      withCredentials: true,
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('response.status code !== 200');
  }

  async getProductList({ userId }) {
    const url = `${this.apiUrl}/v1/payment/products-list`;
    const params = {
      'filter[products-list][and][user][eq]': userId,
    };
    const response = await this.axios.get(url, {
      params,
      withCredentials: true,
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('response.status code !== 200');
  }

  async getAutoresponders(userId, type) {
    const url = `${this.apiUrl}/v1/autoresponder`;
    const params = {
      'filter[autoresponder][and][user][eq]': userId,
      'filter[autoresponder][and][type][eq]': type,
    };
    const response = await this.axios.get(url, {
      params,
      withCredentials: true,
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('response.status code !== 200');
  }

  // async getAutoresponder(id) {
  //   const url = `${this.apiUrl}/v1/autoresponder/${id}`;
  //   const response = await this.axios.get(url, {
  //     withCredentials: true,
  //   });
  //   if (response.status === 200) {
  //     return response.data;
  //   }
  //   throw new Error('response.status code !== 200');
  // }

  async getMailList(accountId, initialRequest = true, skip = 0, count = 100) {
    const url = `${this.apiUrl}/v1/autoresponder/${accountId}/lists`;
    const response = await this.axios.get(url, {
      withCredentials: true,
      params: {
        skip,
        count,
      },
    });
    if (response.status === 200) {
      // if it's initial request - check meta data and request more data in necessary
      if (initialRequest) {
        // check if meta data provided in response
        const meta = get(response.data, 'data.meta', null) || {};
        // check if total items count provided in meta data
        const { total } = meta;
        const totalItemsCount = Number.parseInt(total, 10);
        if (!Number.isNaN(totalItemsCount) && totalItemsCount > 0) {
          // create more requests to backend to fetch all data
          if (count < totalItemsCount) {
            // calc number of sub requests
            const numberOfSubrequests = Math.ceil((totalItemsCount - count) / count);
            // array of promises for sub requests
            const requests = times(
              numberOfSubrequests,
              (num) => this.getMailList(accountId, false, (num + 1) * count, count),
            );
            const subRequestsResult = await Promise.all(requests);
            // combine main request and sub requests results
            const items = [
              ...(get(response, 'data.data.lists') || []),
              ...flatten(subRequestsResult.map((item) => get(item, 'data.lists') || [])),
            ];
            // save combined result in main request response
            set(response, 'data.data.lists', items);
          }
        }
      }
      return response.data;
    }
    throw new Error('response.status code !== 200');
  }

  async getMailListTags(accountId, initialRequest = true, skip = 0, count = 100) {
    const url = `${this.apiUrl}/v1/autoresponder/${accountId}/tags`;
    const response = await this.axios.get(url, {
      withCredentials: true,
      params: {
        skip,
        count,
      },
    });
    if (response.status === 200) {
      // if it's initial request - check meta data and request more data in necessary
      if (initialRequest) {
        // check if meta data provided in response
        const meta = get(response.data, 'data.meta', null) || {};
        // check if total items count provided in meta data
        const { total } = meta;
        const totalItemsCount = Number.parseInt(total, 10);
        if (!Number.isNaN(totalItemsCount) && totalItemsCount > 0) {
          // create more requests to backend to fetch all data
          if (count < totalItemsCount) {
            // calc number of sub requests
            const numberOfSubrequests = Math.ceil((totalItemsCount - count) / count);
            // array of promises for sub requests
            const requests = times(
              numberOfSubrequests,
              (num) => this.getMailListTags(accountId, false, (num + 1) * count, count),
            );
            const subRequestsResult = await Promise.all(requests);
            // combine main request and sub requests results
            const items = [
              ...(get(response, 'data.data.tags') || []),
              ...flatten(subRequestsResult.map((item) => get(item, 'data.tags') || [])),
            ];
            // save combined result in main request response
            set(response, 'data.data.tags', items);
          }
        }
      }
      return response.data;
    }
    throw new Error('response.status code !== 200');
  }
}

export default UserRepository;
