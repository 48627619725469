export const NAV_LINKS = 'NAV_LINKS';
export const NAV_CURRENT_ROUTE = 'NAV_CURRENT_ROUTE';
export const LIMITS = 'LIMITS';
export const TARIFF = 'TARIFF';
export const TITLE = 'TITLE';
export const ACTIVE_NAVLINK_ID = 'ACTIVE_NAVLINK_ID';

const getters = {
  [NAV_LINKS]: (state) => state.navbarLinks,
  [NAV_CURRENT_ROUTE]: (state) => state.activeNavRoute,
  [LIMITS]: (state) => state.limits,
  [TARIFF]: (state) => state.tariff,
  [TITLE]: (state) => state.title,
  [ACTIVE_NAVLINK_ID]: (state) => state.activeNavbarLink,
};

export default getters;
