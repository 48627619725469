import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import maxBy from 'lodash/maxBy';
import max from 'lodash/max';
import min from 'lodash/min';

import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

function tuneUpTotalLeads(data, struct) {
  return data.reduce((result, item, index) => {
    const getValue = (i) => {
      const value = (i >= 0) ? result[i][struct.value] : item[struct.value];
      if (typeof value === 'object') { return value[struct.value]; }
      return value;
    };
    const curr = getValue();
    const prev = getValue(index - 1);
    result.push({
      ...item,
      [struct.value]: Math.max(curr, prev),
    });
    return result;
  }, []);
}

const actions = {
  'total-leads': maxBy,
};

const tuneUpActions = {
  'total-leads': tuneUpTotalLeads,
};

export const groupChartsData = (data, type, part, struct = { date: 'date', value: 'value' }) => {
  let result = [];
  const groups = groupBy(data, (item) => moment.utc(item[struct.date]).startOf(part).valueOf());
  const groupsKeys = Object.keys(groups).map((g) => +g);
  if (groupsKeys.length > 0) {
    const range = moment.range(
      moment.utc(min(groupsKeys)),
      moment.utc(max(groupsKeys)),
    );
    const action = actions[type] || sumBy;
    const dateRange = Array.from(range.snapTo(part).by(part));
    result = dateRange.map((date) => ({
      date: date.format('DD.MM.YYYY'),
      [struct.value]: action(groups[date.valueOf()], struct.value) || 0,
    }));

    const tuneUp = tuneUpActions[type];
    if (tuneUp) {
      result = tuneUp(result, struct);
    }
  }
  return result;
};

export default {
  groupChartsData,
};
