import funnelsManager from '@/services/funnelsManager';
import domainsManager from '@/services/domainsManager';
import usersManager from '@/services/usersManager';
import funnelStatisticManager from '@/services/funnelStatisticManager';
import * as mutations from './mutations';
import { GET_ONLY_CHANGED_FUNNEL_ATTRIBUTES } from './getters';

export const LOAD_FUNNEL = 'LOAD_FUNNEL';
export const LAOAD_FUNNEL_DOMAIN = 'LAOAD_FUNNEL_DOMAIN';
export const SAVE_FUNNEL_ATTRS = 'SAVE_FUNNEL_ATTRS';
export const CLONE_FUNNEL = 'CLONE_FUNNEL';
export const RESET_STATS = 'RESET_STATS';

const actions = {
  [LOAD_FUNNEL]: async ({ dispatch, commit }, id) => {
    const { user, ...funnel } = await funnelsManager.getFunnel(id);
    await dispatch(LAOAD_FUNNEL_DOMAIN, funnel.domainId);

    if (user?.attributes?.id) {
      commit(mutations.SET_USER, user);
    } else if (user?.id) {
      commit(mutations.SET_USER, await usersManager.getUser(user.id, { 'fields[users]': 'id,slug' }, true));
    }

    commit(mutations.SET_FUNNEL, funnel);
    commit(mutations.RESET_FUNNEL_CHANGED);
    return funnel;
  },
  [LAOAD_FUNNEL_DOMAIN]: async ({ commit }, domainId) => {
    if (domainId) {
      const domain = await domainsManager.getDomain(domainId);
      commit(mutations.SET_FUNNEL_DOMAIN, domain);
    } else {
      commit(mutations.SET_FUNNEL_DOMAIN, null);
    }
  },
  [SAVE_FUNNEL_ATTRS]: async (
    {
      dispatch, getters, state, commit, rootState,
    },
    attrs,
  ) => {
    const changedAttrs = getters[GET_ONLY_CHANGED_FUNNEL_ATTRIBUTES](attrs);
    const keys = Object.keys(changedAttrs);

    const funnel = await funnelsManager.update(
      {
        id: state.funnel.id,
        ...changedAttrs,
      },
      rootState.currentUserId,
    );

    if (keys.includes('domainId')) {
      await dispatch(LAOAD_FUNNEL_DOMAIN, changedAttrs.domainId);
    }

    if (funnel.id) {
      commit(mutations.SET_FUNNEL, funnel);
      commit(mutations.RESET_ATTRIBUTES, keys);
    }
  },

  [CLONE_FUNNEL]: async ({ rootState }, { id, attributes }) => {
    const funnel = await funnelsManager.clone(id, attributes, rootState.currentUserId);
    return funnel;
  },
  [RESET_STATS]: ({ state }) => funnelStatisticManager.resetStatistics(state.funnel?.revisionId),
};

export default actions;
