export const SET_MAIN_USER = 'SET_MAIN_USER';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';
export const SET_AUTHORIZED_STATUS = 'SET_AUTHORIZED_STATUS';
export const SET_AVAILABLE_USERS = 'SET_AVAILABLE_USERS';
export const SET_DOMAINS = 'SET_DOMAINS';
export const SET_EXPIRED = 'SET_EXPIRED';
export const UNSET_EXPIRED = 'UNSET_EXPIRED';
export const SET_USER_AVATARS = 'SET_USER_AVATARS';

export default {
  [SET_MAIN_USER](state, user) {
    state.mainUser = user;
  },
  [SET_CURRENCY](state, currency) {
    state.currency = currency;
  },
  [SET_CURRENT_USER_ID](state, userId) {
    state.currentUserId = userId;
  },
  [SET_AUTHORIZED_STATUS](state, status) {
    state.isAuthorized = status;
  },
  [SET_AVAILABLE_USERS](state, users) {
    state.availableUsers = users;
  },
  [SET_DOMAINS](state, domains) {
    state.domains = domains;
  },
  [SET_EXPIRED]: (state) => {
    state.isExpired = true;
  },
  [UNSET_EXPIRED]: (state) => {
    state.isExpired = false;
  },
  [SET_USER_AVATARS]: (state, urls) => {
    state.avatarUrls = urls;
  },
};
