// T (FW)

import * as FUNNEL_TYPES from './funnelsType';

export const BRIDGE = 1;
export const OPTINPAGE = 2;
export const CONFIRMATION = 4;
export const OPTIN_THANK_YOU = 5;
export const ORDER_FORM = 8;
export const BRIDGE_REDIRECT = 10;
export const ORDER_THANK_YOU = 12;
export const OPTIN_AFTER_ORDERFORM_FORM = 13;
export const OPTIN_AFTER_FREE_WEBINAR_FORM = 14;
export const SALES = 15;
export const UPSELL = 16;
export const DOWNSELL = 17;
export const WEBINAR_REPLAY = 3;
export const WEBINAR_ROOM_CARD = 6;
export const WEBINAR_REGISTRATION = 18;
export const WEBINAR_COUNTDOWN = 19;
export const CANDITATUREFORM = 20;
export const FREE_WEBINAR_THANK_YOU = 21;

export const IMPRINT = 22;
export const PRIVACY_POLICY = 23;

export const EMAIL_CONFIRMATION = 70;

export const REVENUE_STEPS_TYPES = [ORDER_FORM, UPSELL, DOWNSELL];

export const STEPS_WITH_VARIATIONS_CONVERSATION_RATE = [
  OPTINPAGE,
  WEBINAR_REGISTRATION,
];

const TYPES = [
  {
    key: 'B',
    num: BRIDGE,
    slug: 'bridge',
    title: 'Bridge',
  },
  {
    key: 'O',
    num: OPTINPAGE,
    slug: 'optinpage',
    title: 'Optinpage',
  },
  {
    key: 'C',
    num: CONFIRMATION,
    slug: 'confirmation',
    title: 'Confirmation',
  },
  {
    key: 'T',
    num: OPTIN_THANK_YOU,
    slug: 'optin-thank-you',
    title: 'Optin Thank You',
  },
  {
    key: 'OF',
    num: ORDER_FORM,
    slug: 'order-form',
    title: 'Order Form',
  },
  {
    key: 'BR',
    num: BRIDGE_REDIRECT,
    slug: 'bridge-redirect',
    title: 'Bridge Redirect',
  },
  {
    key: 'TOF',
    num: ORDER_THANK_YOU,
    slug: 'order-thank-you',
    title: 'Order Thank You',
  },
  {
    key: 'OOF',
    num: OPTIN_AFTER_ORDERFORM_FORM,
    slug: 'optin-after-orderform-form',
    title: 'Optin after Orderform (Form)',
  },
  {
    key: 'OFW',
    num: OPTIN_AFTER_FREE_WEBINAR_FORM,
    slug: 'optin-after-free-webinar-form',
    title: 'Optin after free Webinar (Form)',
  },
  {
    key: 'S',
    num: SALES,
    slug: 'sales',
    title: 'Sales',
  },
  {
    key: 'US',
    num: UPSELL,
    slug: 'upsell',
    title: 'Upsell',
  },
  {
    key: 'DS',
    num: DOWNSELL,
    slug: 'downsell',
    title: 'Downsell',
  },
  {
    key: 'REP',
    num: WEBINAR_REPLAY,
    slug: 'webinar-replay',
    title: 'WebinarReplay',
  },
  {
    key: 'W',
    num: WEBINAR_ROOM_CARD,
    slug: 'webinar-room-card',
    title: 'WebinarRoomCard',
  },
  {
    key: 'R',
    num: WEBINAR_REGISTRATION,
    slug: 'webinar-registration',
    title: 'Webinar Registration',
  },
  {
    key: 'WC',
    num: WEBINAR_COUNTDOWN,
    slug: 'webinar-countdown',
    title: 'Webinar Countdown',
  },
  {
    key: 'F',
    num: CANDITATUREFORM,
    slug: 'canditatureform',
    title: 'Canditatureform',
  },
  {
    key: 'TFW',
    num: FREE_WEBINAR_THANK_YOU,
    slug: 'free-webinar-thank-you',
    title: 'Free Webinar Thank You',
  },

  {
    num: IMPRINT,
    slug: 'imprint',
  },
  {
    num: PRIVACY_POLICY,
    slug: 'privacy-policy',
  },
  {
    num: EMAIL_CONFIRMATION,
    slug: 'email-confirmation',
  },
];

export function getStepTypeByNum(num) {
  const step = TYPES.find((type) => type.num === num);
  if (!step) {
    throw new Error(`step ${num} is not defined`);
  }
  return step;
}

export const DEFAULT_STEPS = {
  [FUNNEL_TYPES.FREE_WEBINAR_AFFILIATE]: [
    WEBINAR_REGISTRATION,
    OPTIN_THANK_YOU,

    WEBINAR_COUNTDOWN,
    WEBINAR_ROOM_CARD,
    WEBINAR_REPLAY,

    // BRIDGE_REDIRECT // not used
  ],
  [FUNNEL_TYPES.FREE_WEBINAR_APPLICATIONFORM]: [
    WEBINAR_REGISTRATION,
    OPTIN_THANK_YOU,

    WEBINAR_COUNTDOWN,
    WEBINAR_ROOM_CARD,
    WEBINAR_REPLAY,

    CANDITATUREFORM,
    FREE_WEBINAR_THANK_YOU,
  ],
  [FUNNEL_TYPES.FREE_WEBINAR]: [
    WEBINAR_REGISTRATION,
    OPTIN_THANK_YOU,

    WEBINAR_COUNTDOWN,
    WEBINAR_ROOM_CARD,
    WEBINAR_REPLAY,

    FREE_WEBINAR_THANK_YOU,
  ],
  [FUNNEL_TYPES.OPTIN_SALESPAGE_AFFILIATE]: [
    OPTINPAGE,
    SALES,
    // BRIDGE_REDIRECT // not used
  ],
  [FUNNEL_TYPES.OPTIN_SALESPAGE]: [
    OPTINPAGE,
    SALES,
    ORDER_FORM,
    // UPSELL,
    // DOWNSELL,
    ORDER_THANK_YOU,
  ],
  [FUNNEL_TYPES.OPTIN]: [
    // BRIDGE,
    OPTINPAGE,
    OPTIN_THANK_YOU,
  ],
  [FUNNEL_TYPES.ORDERFORM]: [
    ORDER_FORM,
    // UPSELL,
    // DOWNSELL,
    ORDER_THANK_YOU,
  ],
  [FUNNEL_TYPES.SALESPAGE_AFFILIATE]: [
    SALES,
    // BRIDGE_REDIRECT // not used
  ],
  [FUNNEL_TYPES.SALESPAGE]: [
    SALES,
    ORDER_FORM,
    // UPSELL,
    // DOWNSELL,
    ORDER_THANK_YOU,
  ],
  [FUNNEL_TYPES.WEBINAR_APPLICATIONFORM]: [
    // webinar_canditature
    WEBINAR_REGISTRATION,
    OPTIN_THANK_YOU,
    WEBINAR_COUNTDOWN,
    WEBINAR_ROOM_CARD,
    WEBINAR_REPLAY,
    ORDER_FORM,
    // UPSELL,
    // DOWNSELL,
    CANDITATUREFORM,
    ORDER_THANK_YOU,
  ],
  [FUNNEL_TYPES.WEBINAR]: [
    WEBINAR_REGISTRATION,
    OPTIN_THANK_YOU,
    WEBINAR_COUNTDOWN,
    WEBINAR_ROOM_CARD,
    WEBINAR_REPLAY,
    ORDER_FORM,
    // UPSELL,
    // DOWNSELL,
    ORDER_THANK_YOU,
  ],
  [FUNNEL_TYPES.OPTIN_APPLICATIONFORM]: [
    OPTINPAGE,
    CANDITATUREFORM,
    OPTIN_THANK_YOU,
  ],
  [FUNNEL_TYPES.APPLICATIONFORM]: [
    //
    CANDITATUREFORM,
    OPTIN_THANK_YOU,
  ],
};

export function getFunnelSteps(funnelType) {
  const steps = DEFAULT_STEPS[funnelType];
  if (steps) {
    return [...steps, IMPRINT, PRIVACY_POLICY];
  }
}

export default TYPES;
