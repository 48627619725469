import moment from 'moment';

export const COUNT = 'COUNT';
export const BY_TYPE = 'BY_TYPE';

const getters = {
  [COUNT]: (state) => state.notifications.length,
  [BY_TYPE]: (state) => (type, descending = false) => {
    const sortMultiplier = descending ? 1 : -1;
    return state.notifications
      .filter((notification) => notification.attributes.type === type)
      .sort((a, b) => {
        let aSec = moment.duration(a.attributes.time).asSeconds();
        let bSec = moment.duration(b.attributes.time).asSeconds();
        let diff = aSec - bSec;
        if (diff) {
          return sortMultiplier * diff;
        }
        aSec = moment.duration(a.attributes['visit-time']).asSeconds();
        bSec = moment.duration(b.attributes['visit-time']).asSeconds();
        diff = aSec - bSec;
        return diff;
      });
  },
};

export default getters;
