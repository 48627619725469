import { PROTOCOL, DOMAIN_CUSTOMERS } from '@dmant/ez-env-common';

export const GET_FUNNEL = 'GET_FUNNEL';
export const HAS_ANY_CHANGED_FUNNEL_ATTRIBUTE = 'HAS_ANY_CHANGED_FUNNEL_ATTRIBUTE';
export const GET_FUNNEL_CHANGED_ATTRIBUTE = 'GET_FUNNEL_CHANGED_ATTRIBUTE';
export const GET_ONLY_CHANGED_FUNNEL_ATTRIBUTES = 'GET_ONLY_CHANGED_FUNNEL_ATTRIBUTES';

export const FUNNEL_BASE_URL = 'FUNNEL_BASE_URL';
export const FUNNEL_URL = 'FUNNEL_URL';

const gettersFunnelStore = {
  [GET_FUNNEL]: (state) => state.funnel,
  [HAS_ANY_CHANGED_FUNNEL_ATTRIBUTE]: (state) => (attrs) => {
    for (const attr of attrs) {
      if (state.funnelChanged[attr]) {
        return true;
      }
    }
    return false;
  },
  [GET_FUNNEL_CHANGED_ATTRIBUTE]: (state) => (attr) => {
    if (state.funnelChanged[attr]) {
      return state.funnelChanged[attr].value;
    }
    return state.funnel[attr];
  },
  [GET_ONLY_CHANGED_FUNNEL_ATTRIBUTES]: (state) => (attrs) => {
    const result = {};
    for (const attr of attrs) {
      const changedAttr = state.funnelChanged[attr];
      if (changedAttr) {
        result[attr] = changedAttr.value;
      }
    }
    return result;
  },
  [FUNNEL_BASE_URL](state) {
    if (state.funnelDomain) {
      const { protocol, host } = state.funnelDomain;
      return `${protocol}//${host}`;
    }
    if (state.user) {
      const { slug } = state.user.attributes;
      return `${PROTOCOL}//${slug}.${DOMAIN_CUSTOMERS}`;
    }
    return '';
  },
  [FUNNEL_URL](state, getters) {
    // <domain>/<funnel_slug>
    // где домен может быть - либо домен что присоединили.
    // (у домена уже есть информация - http или https)
    // либо https://<user_slug>.ezpage.com (edited)
    if (state.funnel) {
      const baseUrl = getters[FUNNEL_BASE_URL];
      const { slug } = state.funnel;
      if (slug) {
        return `${baseUrl}/${slug}`;
      }
    }
    return '';
  },
};

export default gettersFunnelStore;
