import { OPTIONS } from '@/enums';
import i18n from '@/i18n';

import groupsManager from '@/services/groupsManager';
import funnelsManager from '@/services/funnelsManager';

import { SET_HEADER_TABS } from '@/store/header/mutations';
import { GET_HEADER_TABS } from './getters';
import * as mutations from './mutations';

export const LOAD_GROUPS = 'LOAD_GROUPS';
export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const REORDER_GROUPS = 'REORDER_GROUPS';

export const LOAD_FUNNELS = 'LOAD_FUNNELS';
export const LOAD_LATEST_FUNNELS = 'LOAD_LATEST_FUNNELS';
export const LOAD_ARCHIVE = 'LOAD_ARCHIVE';
export const LOAD_ARCHIVE_COUNT = 'LOAD_ARCHIVE_COUNT';
export const LOAD_ALL_COUNT = 'LOAD_ALL_COUNT';
export const UPDATE_FUNNELS = 'UPDATE_FUNNELS';

export const LOAD_NAV_TABS = 'LOAD_NAV_TABS';

const actions = {
  [LOAD_GROUPS]: async ({ commit, rootState }) => {
    const groups = await groupsManager
      .getList(rootState.currentUserId);

    groups.push({
      id: 'ungrouped',
      color: OPTIONS.GROUPS_COLOR,
      name: i18n.t('groups.ungroupedFunnels'),
      checked: false,
      opened: false,
      // funnelCount
    });
    commit(mutations.SET_GROUPS, groups);
  },

  [CREATE_GROUP]: async ({ commit, rootState }, params) => {
    const newGroup = await groupsManager
      .create(params, rootState.currentUserId);
    if (newGroup && newGroup.id) {
      commit(mutations.ADD_GROUP, newGroup);
      return newGroup.id;
    }
    return null;
  },

  [UPDATE_GROUP]: async ({ state, rootState, commit }, { id, values }) => {
    const group = state.groups.find((g) => g.id === id);
    if (group) {
      const newValue = { ...group, ...values };
      const editedGroup = await groupsManager
        .update(newValue, rootState.currentUserId);
      commit(mutations.REPLACE_GROUP, editedGroup);
    }
  },

  [DELETE_GROUP]: async ({ state, commit }, id) => {
    const isDelete = await groupsManager.delete(id);
    if (isDelete) {
      commit(mutations.DELETE_GROUP_ID, id);

      const updatedFunnels = state.funnels.map((funnel) => {
        const updatedFunnel = { ...funnel };
        updatedFunnel.groupId = updatedFunnel.groupId === id ? 'ungrouped' : updatedFunnel.groupId;
        return updatedFunnel;
      });
      commit(mutations.SET_FUNNELS, updatedFunnels);
    }
  },

  [REORDER_GROUPS]: async ({ commit }, groups) => {
    const ids = groups.map((g) => g.id);

    groups.push({
      id: 'ungrouped',
      color: OPTIONS.GROUPS_COLOR,
      name: i18n.t('groups.ungroupedFunnels'),
      checked: false,
      opened: false,
    });

    commit(mutations.SET_GROUPS, groups);
    await groupsManager.reorder(ids);
  },

  [LOAD_FUNNELS]: async ({
    state, commit, dispatch, rootState,
  }) => {
    commit(mutations.SET_FUNNELS_LOADING, true);
    if (state.funnels.length !== 0) {
      commit(mutations.SET_FUNNELS, []);
      commit(mutations.SET_PAGINATION, {});
    }
    const funnels = await funnelsManager
      .getFunnels(rootState.currentUserId);
    commit(mutations.SET_FUNNELS, funnels.data);
    commit(mutations.SET_PAGINATION, funnels.meta);
    await dispatch(LOAD_GROUPS);
    commit(mutations.SET_FUNNELS_LOADING, false);
  },

  [LOAD_LATEST_FUNNELS]: async ({ commit, rootState }) => {
    const funnels = await funnelsManager
      .getLatestFunnels(rootState.currentUserId);
    commit(mutations.SET_LATEST_FUNNELS, funnels.data);
  },

  [LOAD_ARCHIVE]: async ({ state, commit, rootState }) => {
    if (state.funnels.length !== 0 && !state.page) {
      commit(mutations.SET_FUNNELS, []);
      commit(mutations.SET_PAGINATION, {});
    }

    const pageSetting = state.search
      ? {
        page: 1,
        limit: state.archiveCount || 5000,
      }
      : {
        page: state.page,
        limit: state.limit,
      };

    const funnels = await funnelsManager
      .getArchive(rootState.currentUserId, pageSetting);
    commit(mutations.SET_PAGINATION, funnels.meta);
    commit(mutations.SET_FUNNELS, funnels.data);
  },

  [LOAD_ARCHIVE_COUNT]: async ({ commit, rootState }) => {
    // https://api.funnels.hotcoder.ru/v1/funnels?fields[funnels]=id&page[number]=1&page[size]=1&filter[funnels][and][user][eq]=c77696a2-b021-42be-b6fe-4079e9fc2b7f&filter[funnels][and][archive][eq]=true
    const count = await funnelsManager
      .getArchiveCount(rootState.currentUserId);
    commit(mutations.SET_ARCHIVE_COUNT, count);
  },

  [LOAD_ALL_COUNT]: async ({ commit, rootState }) => {
    const count = await funnelsManager
      .getAllCount(rootState.currentUserId);
    commit(mutations.SET_ALL_COUNT, count);
  },

  [UPDATE_FUNNELS]: async (
    {
      commit, state, dispatch, rootState,
    },
    { ids, values },
  ) => {
    const funnels = state.funnels.filter((f) => ids.includes(f.id));
    if (funnels) {
      const promises = funnels.map((funnel) => funnelsManager
        .update({ ...funnel, ...values }, rootState.currentUserId));
      const newFunnels = await Promise.all(promises);
      const updatedIds = newFunnels.map((f) => f.id).filter(Boolean);
      const { length } = updatedIds;
      if (length > 0 && Object.prototype.hasOwnProperty.call(values, 'archive')) {
        for (const id of updatedIds) {
          commit(mutations.DELETE_FUNNEL_ID, id);
        }
        let { archiveCount } = state;
        archiveCount += length * (values.archive ? 1 : -1);
        commit(mutations.SET_ARCHIVE_COUNT, archiveCount);
        dispatch(SET_HEADER_TABS);
      }
    }
  },

  [SET_HEADER_TABS]: async ({ getters, commit, rootState }) => {
    if (rootState.header.activeNavbarLink === 'funnels') {
      commit(`header/${SET_HEADER_TABS}`, getters[GET_HEADER_TABS], {
        root: true,
      });
    }
  },

  [LOAD_NAV_TABS]: async ({ dispatch }) => {
    await dispatch(LOAD_ARCHIVE_COUNT);
    dispatch(SET_HEADER_TABS);
  },
};

export default actions;
