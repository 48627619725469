import { OPTIONS } from '../../enums';

const state = {
  search: '',
  groups: [],
  funnelsLoading: true,
  latestFunnels: [],
  funnels: [],
  archiveCount: 0,
  allCount: 0,
  pagination: {},
  page: OPTIONS.DEFAULT_PAGES_LIMIT.page,
  limit: OPTIONS.DEFAULT_PAGES_LIMIT.limit,
};

export default state;
