import { Validator } from 'vee-validate';
import validationMessagesEn from 'vee-validate/dist/locale/en';
import validationMessagesDe from 'vee-validate/dist/locale/de';
import { mapLocales, EN, DE } from '@/enums/locales';

const dictionary = {
  [EN]: validationMessagesEn,
  [DE]: validationMessagesDe,
};

const customMessages = {
  en: {
    custom: {
      'm-subject': {
        min: 'Subject need to be at least 2 characters long',
      },
      'm-template-id': {
        required: 'Please select a template above',
      },
    },
  },
  de: {
    custom: {
      'm-subject': {
        min: 'Betreff muss min. 2 Zeichen enthalten',
      },
      'm-template-id': {
        required: 'Bitte wähle ein Template aus',
      },
    },
  },
};

export default function loadVeeValidateLocale(code) {
  const locale = mapLocales[code] || EN;
  const dict = dictionary[locale];
  Validator.localize(locale, dict);
  Validator.localize(locale, customMessages[locale]);
}
