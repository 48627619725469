import * as NUMS from '@/enums/funnelsTypeNums';
import * as TYPES from '@/enums/funnelsType';

// num: NUMS.OPTIN_SALESPAGE_AFFILIATE = 'optin_salespage_affiliate'
// removed: TYPES.OPTIN_SALESPAGE_AFFILIATE = 'optin_salespage_affiliate'
// using optin numr TYPES.OPTIN_APPLICATIONFORM = 'optin_applicationform'
// using optin for TYPES.OPTIN_APPLICATIONFORM = 'optin_applicationform'

export const FREE_WEBINAR = {
  type: 'funnel',
  id: 'free_webinar',
  slug: 'free-webinar',
  image: 'main-use-webinar-yes.png',
  num: NUMS.FREE_WEBINAR,
  value: TYPES.FREE_WEBINAR,
  types: ['all', 'webinar', 'free'],
  funnelmap: [
    {
      group: 'optin',
      steps: [
        ['Web. Optin'],
        ['optin-thank-you'],
      ],
    },
    {
      group: 'webinar',
      steps: [
        ['webinar-countdown'],
        ['webinar-room-card'],
        ['webinar-replay'],
      ],
    },
    {
      group: 'finish',
      steps: [
        ['order-thank-you'],
      ],
    },
  ],
};

export const FREE_WEBINAR_APPLICATIONFORM = {
  type: 'funnel',
  id: 'free_webinar_canditature',
  slug: 'free-webinar-canditature',
  image: 'main-with-questionary-yes.png',
  num: NUMS.FREE_WEBINAR_APPLICATIONFORM,
  value: TYPES.FREE_WEBINAR_APPLICATIONFORM,
  types: ['all', 'webinar', 'applicationform', 'free'],
  funnelmap: [
    {
      group: 'optin',
      steps: [
        ['Web. Optin'],
        ['optin-thank-you'],
      ],
    },
    {
      group: 'webinar',
      steps: [
        ['webinar-countdown'],
        ['webinar-room-card'],
        ['webinar-replay'],
      ],
    },
    {
      group: 'finish',
      steps: [
        ['canditatureform'],
        ['order-thank-you'],
      ],
    },
  ],
};

export const OPTIN = {
  type: 'funnel',
  id: 'optin_salespage',
  slug: 'optin-salespage',
  image: 'main-get-leads-yes.png',
  types: ['all', 'sales'],
  num: NUMS.OPTIN_SALESPAGE,
  value: TYPES.OPTIN_SALESPAGE,
  funnelmap: [
    {
      group: 'optin',
      steps: [
        ['Web. Optin'],
      ],
    },
    {
      group: 'sales',
      steps: [
        ['sales'],
        ['order-form'],
        ['upsell', 'downsell'],
      ],
    },
    {
      group: 'finish',
      steps: [
        ['order-thank-you'],
      ],
    },
  ],
};

export const SALESPAGE = {
  type: 'funnel',
  id: 'salespage',
  slug: 'salespage',
  image: 'main-what-sell-own-salespage.png',
  num: NUMS.SALESPAGE,
  value: TYPES.SALESPAGE,
  types: ['all', 'sales'],
  funnelmap: [
    {
      group: 'sales',
      steps: [
        ['sales'],
        ['order-form'],
        ['upsell', 'downsell'],
      ],
    },
    {
      group: 'finish',
      steps: [
        ['order-thank-you'],
      ],
    },
  ],
};

export const SALESPAGE_AFFILIATE = {
  type: 'funnel',
  id: 'salespage_affiliate',
  slug: 'salespage-affiliate',
  image: 'main-what-sell-affiliate-salespage.png',
  num: NUMS.SALESPAGE_AFFILIATE,
  value: TYPES.SALESPAGE_AFFILIATE,
  types: ['all', 'sales', 'affiliate'],
  funnelmap: [
    {
      group: 'sales',
      steps: [
        ['sales'],
      ],
    },
  ],
};

export const WEBINAR_APPLICATIONFORM = {
  type: 'funnel',
  id: 'webinar_canditature',
  slug: 'webinar-canditature',
  image: 'main-with-questionary-yes.png',
  num: NUMS.WEBINAR_APPLICATIONFORM,
  value: TYPES.WEBINAR_APPLICATIONFORM,
  types: ['all', 'webinar', 'applicationform'],
  funnelmap: [
    {
      group: 'optin',
      steps: [
        ['Web. Optin'],
        ['optin-thank-you'],
      ],
    },
    {
      group: 'webinar',
      steps: [
        ['webinar-countdown'],
        ['webinar-room-card'],
        ['webinar-replay'],
      ],
    },
    {
      group: 'sales',
      steps: [
        ['order-form'],
        ['upsell', 'downsell'],
      ],
    },
    {
      group: 'finish',
      steps: [
        ['canditatureform'],
        ['order-thank-you'],
      ],
    },
  ],
};

export const WEBINAR = {
  type: 'funnel',
  id: 'webinar',
  slug: 'webinar',
  image: 'main-what-sell-own.png',
  num: NUMS.WEBINAR,
  value: TYPES.WEBINAR,
  types: ['all', 'webinar'],
  funnelmap: [
    {
      group: 'optin',
      steps: [
        ['Web. Optin'],
        ['optin-thank-you'],
      ],
    },
    {
      group: 'webinar',
      steps: [
        ['webinar-countdown'],
        ['webinar-room-card'],
        ['webinar-replay'],
      ],
    },
    {
      group: 'sales',
      steps: [
        ['order-form'],
        ['upsell', 'downsell'],
      ],
    },
    {
      group: 'finish',
      steps: [
        ['order-thank-you'],
      ],
    },
  ],
};

export const FREE_WEBINAR_AFFILIATE = {
  type: 'funnel',
  id: 'free_webinar_affiliate',
  slug: 'free-webinar-affiliate',
  image: 'main-what-sell-affiliate.png',
  num: NUMS.FREE_WEBINAR_AFFILIATE,
  value: TYPES.FREE_WEBINAR_AFFILIATE,
  types: ['all', 'webinar', 'free', 'affiliate'],
  funnelmap: [
    {
      group: 'optin',
      steps: [
        ['Web. Optin'],
        ['optin-thank-you'],
      ],
    },
    {
      group: 'webinar',
      steps: [
        ['webinar-countdown'],
        ['webinar-room-card'],
        ['webinar-replay'],
      ],
    },
  ],
};

export const ORDERFORM = {
  type: 'funnel',
  id: 'orderform',
  slug: 'orderform',
  image: 'main-how-sell-product-orderform.png',
  num: NUMS.ORDERFORM,
  value: TYPES.ORDERFORM,
  types: ['all'],
  funnelmap: [
    {
      group: 'sales',
      steps: [
        ['order-form'],
        ['upsell', 'downsell'],
      ],
    },
    {
      group: 'finish',
      steps: [
        ['order-thank-you'],
      ],
    },
  ],
};

export const APPLICATIONFORM = {
  type: 'funnel',
  id: 'applicationform',
  slug: 'applicationform',
  image: 'main-how-gather-leads-market-demand-analysis.png',
  num: NUMS.APPLICATIONFORM,
  value: TYPES.APPLICATIONFORM,
  types: ['all'],
  funnelmap: [
    {
      group: 'optin',
      steps: [
        ['canditatureform'],
      ],
    },
    {
      group: 'finish',
      steps: [
        ['order-thank-you'],
      ],
    },
  ],
};

export const OPTIN_APPLICATIONFORM = {
  type: 'funnel',
  id: 'optin',
  slug: 'optin',
  image: 'main-how-gather-leads-offer-a-free-product-as-lead-magnet.png',
  num: NUMS.OPTIN,
  value: TYPES.OPTIN,
  types: ['all', 'optin'],
  funnelmap: [
    {
      group: 'optin',
      steps: [
        ['Web. Optin'],
      ],
    },
    {
      group: 'finish',
      steps: [
        ['order-thank-you'],
      ],
    },
  ],
};

export default [
  SALESPAGE,
  WEBINAR,
  OPTIN_APPLICATIONFORM,
  FREE_WEBINAR_AFFILIATE,
  OPTIN,
  WEBINAR_APPLICATIONFORM,
  APPLICATIONFORM,
  FREE_WEBINAR,
  FREE_WEBINAR_APPLICATIONFORM,
  SALESPAGE_AFFILIATE,
  ORDERFORM,
];
