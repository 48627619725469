import { updateField } from 'vuex-map-fields';

export const CLEAN = 'CLEAN';

export default {
  updateField,
  [CLEAN](state) {
    state.templates = [];
    state.name = '';
    state.slug = '';
    state.locale = '';
    state.domainId = '';
  },
};
