import templatePreview from '@/helpers/templatePreview';

import {
  ORDER_FORM,
  UPSELL,
  DOWNSELL,
  STEPS_WITH_VARIATIONS_CONVERSATION_RATE,
} from '@/enums/stepTypes';

import builderManager from '@/services/builderManager';
import stepsManager from '@/services/stepsManager';
import funnelStatisticManager from '@/services/funnelStatisticManager';
import usersManager from '@/services/usersManager';
import * as mutations from './mutations';

const TYPES_WITH_PARENT = [DOWNSELL];

export const INIT = 'INIT';
export const SAVE_STEP_FALLBACK = 'SAVE_STEP_FALLBACK';
export const REMOVE_STEP_FALLBACK = 'REMOVE_STEP_FALLBACK';
export const CREATE_STEP_PRODUCT = 'CREATE_STEP_PRODUCT';
export const SAVE_STEP_PRODUCT = 'SAVE_STEP_PRODUCT';
export const REMOVE_STEP_PRODUCT = 'REMOVE_STEP_PRODUCT';
export const SAVE_STEP_ATTRIBUTES = 'SAVE_STEP_ATTRIBUTES';
export const SAVE_VARIATION_TEMPLATE = 'SAVE_VARIATION_TEMPLATE';
export const MOVE_STEPS = 'MOVE_STEPS';
export const ADD_VARIATION = 'ADD_VARIATION';
export const CHANGE_VARIATIONS_PERCENTS = 'CHANGE_VARIATIONS_PERCENTS';
export const DETACH_VARIATION_TEMPLATE = 'DETACH_VARIATION_TEMPLATE';
export const RESTORE_VARIATION = 'RESTORE_VARIATION';
export const ARCHIVE_VARIATION = 'ARCHIVE_VARIATION';
export const SET_WINNER = 'SET_WINNER';
export const DELETE_STEP = 'DELETE_STEP';
export const CREATE_STEP = 'CREATE_STEP';
export const CREATE_UPSELL = 'CREATE_UPSELL';
export const CHANGE_PAYMENT_PROVIDER_ID = 'CHANGE_PAYMENT_PROVIDER_ID';
export const LOAD_PRODUCT_LIST = 'LOAD_PRODUCT_LIST';

function setTemplateMetaPreview(template) {
  if (!template.meta) {
    template.meta = {};
  }
  if (!template.meta.preview) {
    template.meta.preview = templatePreview(
      template.attributes,
      500,
      template.attributes && template.attributes['dt-update'],
    );
  }
  return template;
}

async function getTemplate(id) {
  if (!id) {
    throw new Error(`template id "${id}" is empty`);
  }
  const result = await builderManager.getTemplatesByIds([id]);
  for (const template of result.data) {
    return setTemplateMetaPreview(template);
  }
  throw new Error(`template id "${id}" not found`);
}

const actions = {
  [INIT]: async ({ commit, state }, { funnel, stepComponent, init }) => {
    if (state.init === init) {
      return;
    }
    commit(mutations.INIT, init);

    const steps = await stepsManager.load(funnel);

    // load templates
    const tplMap = {};
    const ids = [];
    let archiveLength = 0;
    let archiveVariationLength = 0;

    for (const step of steps) {
      archiveLength += step.archive.length;
      for (const variation of step.variations) {
        const id = variation.attributes['template-id'];
        if (id) {
          ids.push(id);
        }
        tplMap[id] = variation;
      }
    }

    if (stepComponent !== 'statistics') {
      if (ids) {
        const result = await builderManager.getTemplatesByIds(ids);
        for (const template of result.data) {
          const { id } = template;
          tplMap[id].template = setTemplateMetaPreview(template);
        }
      }

      // conversation rates
      {
        const converstationRateSteps = [];
        for (const step of steps) {
          const { id, type } = step.attributes;
          const needConversationRate = STEPS_WITH_VARIATIONS_CONVERSATION_RATE.includes(type);
          if (needConversationRate && step.variations.length > 1) {
            converstationRateSteps.push({
              id,
              type,
            });
          }
        }

        const conversationRate = await funnelStatisticManager.getVariationsConversationRate({
          revisionId: funnel.revisionId,
          steps: converstationRateSteps,
        });
        for (const step of steps) {
          for (const statistic of conversationRate) {
            for (const variation of step.variations) {
              if (variation.id === statistic.variationid) {
                variation.statistic = statistic;
              }
            }
            for (const variation of step.archive) {
              if (variation.id === statistic.variationid) {
                variation.statistic = statistic;
                archiveVariationLength++;
              }
            }
          }
        }
      }

      // payment provider
      for (const { product } of steps) {
        const id = product && product.attributes && product.attributes['payment-provider-id'];
        if (id) {
          commit(mutations.SET_PAYMENT_PROVIDER_ID, id);
        }
      }
    }

    commit(mutations.SET_STEPS, steps);
    commit(mutations.SET_ARCHIVE_LENGTH, archiveLength);
    commit(mutations.SET_ARCHIVE_VARIATION_LENGTH, archiveVariationLength);
  },
  [SAVE_STEP_FALLBACK]: async ({ commit }, { step, fallback }) => {
    await stepsManager.saveStepFallback(step.attributes.id, fallback);
    commit(mutations.SET_STEP_FALLBACK, { step, fallback });
  },
  [REMOVE_STEP_FALLBACK]: async ({ commit }, step) => {
    commit(mutations.DELETE_STEP_FALLBACK, step);
    await stepsManager.saveStepFallback(step.attributes.id, null);
  },
  [CREATE_STEP_PRODUCT]: async ({ commit, rootState }, { step, product }) => {
    const userId = rootState.currentUserId;
    const stepId = step.attributes.id;
    const result = await stepsManager.createStepProduct({
      userId,
      stepId,
      product,
    });

    result.data.attributes.name = product.attributes.name;
    await commit(mutations.SET_STEP_PRODUCT, { step, product: result.data });
  },
  [SAVE_STEP_PRODUCT]: async ({ commit }, { step, product }) => {
    const changedAttributes = {};
    for (const [key, value] of Object.entries(product.attributes)) {
      const oldvalue = step.product.attributes[key];
      if (value !== oldvalue) {
        changedAttributes[key] = value;
      }
    }
    const result = await stepsManager.saveStepProduct(
      product.id,
      changedAttributes,
    );

    result.data.attributes.name = product.attributes.name;
    await commit(mutations.SET_STEP_PRODUCT, { step, product: result.data });
  },
  [REMOVE_STEP_PRODUCT]: async ({ commit }, { step }) => {
    await stepsManager.deleteStepProduct(step.product.id);
    await commit(mutations.DELETE_STEP_PRODUCT, step);
  },
  [MOVE_STEPS]: (
    { commit, state },
    { sourceIndex, targetIndex, count },
  ) => {
    const step = state.steps[sourceIndex];
    commit(mutations.MOVE_STEPS, { sourceIndex, targetIndex, count });
    const nextStep = state.steps[targetIndex + count];
    return stepsManager.moveStepBefore(
      step.attributes.id,
      nextStep.attributes.id,
    );
  },
  [DELETE_STEP]: async (
    { commit, state },
    { index, step, сhild },
  ) => {
    const { default: confirmDelete } = await import(/* webpackChunkName: "confirmDelete" */ '@/components/dialogs/confirmDelete');
    const isConfirmed = await confirmDelete('step', step.attributes.name);
    if (!isConfirmed) {
      return;
    }

    await stepsManager.deleteStep(step);

    // remove fallback to the step
    const ids = [step.attributes.id];
    if (сhild) {
      ids.push(сhild.step.attributes.id);
    }
    for (const stepWithFallback of state.steps) {
      if (ids.includes(stepWithFallback.fallback)) {
        commit(mutations.DELETE_STEP_FALLBACK, stepWithFallback);
      }
    }
    if (сhild) {
      commit(mutations.DELETE_STEP_BY_INDEX, сhild.index);
    }
    commit(mutations.DELETE_STEP_BY_INDEX, index);
  },
  [CREATE_STEP]: async (
    {
      commit, state, rootState,
    },
    {
      index, type, name, slug,
    },
  ) => {
    const date = new Date();
    const step = {
      date,
      attributes: {
        type,
        name,
        slug,
      },
      variations: [],
      archive: [],
    };
    function getStepParentOrPrevId() {
      if (index === 0) {
        return {};
      }
      if (TYPES_WITH_PARENT.includes(type)) {
        const parentStep = state.steps[index - 1];
        return {
          'parent-id': parentStep.attributes.id,
        };
      }
      let prevIndex = index;
      let prevStep;
      do {
        prevIndex -= 1;
        if (prevIndex >= 0) {
          prevStep = state.steps[prevIndex];
        } else {
          throw new Error('valid prev step is not found');
        }
      } while (TYPES_WITH_PARENT.includes(prevStep.attributes.type));
      return {
        'prev-id': prevStep.attributes.id,
      };
    }
    const userId = rootState.currentUserId;
    const funnelId = rootState.funnel.funnel.id;
    const { data } = await stepsManager.createStep({
      index,
      step,
      userId,
      funnelId,
      meta: getStepParentOrPrevId(),
    });
    const createdStep = {
      attributes: {
        id: data.id,
        ...data.attributes,
      },
      variations: [],
      archive: [],
    };
    await commit(mutations.INSERT_STEP, { index, step: createdStep });
    return createdStep;
  },
  [SAVE_STEP_ATTRIBUTES]: async ({ commit }, { step, attributes }) => {
    const changed = {};
    for (const [key, value] of Object.entries(attributes)) {
      if (step.attributes[key] !== value) {
        changed[key] = value;
      }
    }
    const stepData = await stepsManager.saveStepAttributes(step.attributes.id, changed);
    const data = stepData.data.attributes;
    if (data['mail-template-id'] && changed['mail-template-id']) {
      changed['mail-template-id'] = data['mail-template-id'];
    }
    commit(mutations.SET_STEP_ATTRIBUTES, { step, attributes: changed });
  },
  [SAVE_VARIATION_TEMPLATE]: async ({ commit }, { variation, template }) => {
    const result = await stepsManager.changeVariationAttributes(variation.id, {
      'template-id': template.id,
    });
    const newTemplateId = result.data.attributes['template-id'];

    commit(mutations.SET_VARIATION_TEMPLATE, {
      variation,
      template: await getTemplate(newTemplateId),
    });
  },
  [DETACH_VARIATION_TEMPLATE]: async ({ commit }, { variation }) => {
    await stepsManager.changeVariationAttributes(variation.id, {
      'template-id': null,
    });
    commit(mutations.DELETE_VARIATION_TEMPLATE, variation);
  },
  [ADD_VARIATION]: async ({ commit, rootState }, { template, step }) => {
    const percent = step.variations.length === 0 ? 100 : 0;

    const stepId = step.attributes.id;
    const templateId = template.id;
    const userId = rootState.currentUserId;
    const funnelId = rootState.funnel.funnel.id;
    const { data: variation } = await stepsManager.createVariation({
      stepId,
      templateId,
      percent,
      userId,
      funnelId,
    });
    const newTemplateId = variation.attributes['template-id'];

    commit(mutations.ADD_VARIATION, {
      step,
      variation,
      template: await getTemplate(newTemplateId),
    });
  },
  [CHANGE_VARIATIONS_PERCENTS]: async ({ commit }, { step, percents }) => {
    const { variations } = step;

    const data = variations.map(({ id }, index) => {
      const percent = percents[index];
      return { id, percent };
    });
    await stepsManager.setVariationsPercent(data);

    commit(mutations.CHANGE_VARIATIONS_PERCENTS, { variations, percents });
  },
  [ARCHIVE_VARIATION]: async ({ commit }, { step, index }) => {
    const variations = JSON.parse(JSON.stringify(step.variations));
    const archiveVariations = variations.splice(index, 1);
    const percents = variations.map((variation) => parseInt(variation.attributes.percent, 10));
    const sum = percents.reduce((z, percent) => z + percent, 0);
    const diff = 100 - sum;
    const updatePercent = diff !== 0 && variations[0];
    if (updatePercent) {
      variations[0].attributes.percent += diff;
    }

    await commit(mutations.SET_VARIATIONS, { step, variations });
    await commit(mutations.ADD_TO_ARCHIVE, {
      step,
      variations: archiveVariations,
    });
    {
      const { id } = archiveVariations[0];
      await stepsManager.changeVariationAttributes(id, { archive: true });
    }
    const main = variations.length === 1;
    if (updatePercent || main) {
      const { id } = variations[0];
      let { percent } = variations[0].attributes;
      if (main) {
        percent = 100;
      }
      await stepsManager.changeVariationAttributes(id, { percent, main });
    }
  },
  [SET_WINNER]: async ({ commit }, { step, index }) => {
    const archiveVariations = JSON.parse(JSON.stringify(step.variations));
    const variations = archiveVariations.splice(index, 1);
    // @TODO move modifications to mutation
    variations[0].attributes.percent = 100;
    await commit(mutations.SET_VARIATIONS, { step, variations });

    await commit(mutations.ADD_TO_ARCHIVE, {
      step,
      variations: archiveVariations,
    });

    for (const { id } of archiveVariations) {
      // eslint-disable-next-line no-await-in-loop
      await stepsManager.changeVariationAttributes(id, {
        archive: true,
        main: false,
      });
    }
    const { id } = variations[0];
    await stepsManager.changeVariationAttributes(id, {
      main: true,
      percent: 100,
    });
  },
  [RESTORE_VARIATION]: async ({ commit }, { step, variation, index }) => {
    const mainVariation = step.variations.find((v) => v.attributes.main);
    const template = variation.template || await getTemplate(variation.attributes['template-id']);

    await commit(mutations.RESTORE_VARIATION, {
      step, variation, template, index,
    });

    if (mainVariation) {
      await stepsManager.changeVariationAttributes(mainVariation.id, {
        main: false,
        archive: false,
      });
    }
    await stepsManager.changeVariationAttributes(variation.id, {
      percent: 0,
      index: variation.attributes.index,
      archive: false,
    });
  },
  [CREATE_UPSELL]: async ({ state, dispatch }, { name, slug }) => {
    const AFTER = [ORDER_FORM, UPSELL, DOWNSELL];
    const index = state.steps.reduce((result, step, idx) => {
      if (AFTER.includes(step.attributes.type)) {
        return idx + 1;
      }
      return result;
    }, 0);
    await dispatch(CREATE_STEP, {
      index, type: UPSELL, name, slug,
    });
  },

  [CHANGE_PAYMENT_PROVIDER_ID]: async ({ state, dispatch, commit }, id) => {
    for (const step of state.steps) {
      if (step.product) {
        await dispatch(REMOVE_STEP_PRODUCT, { step }); // eslint-disable-line no-await-in-loop
      }
    }
    commit(mutations.SET_PAYMENT_PROVIDER_ID, id);
  },

  async [LOAD_PRODUCT_LIST]({
    rootState, commit,
  }) {
    const { data } = await usersManager.getProductList({
      userId: rootState.currentUserId,
    });
    commit(mutations.SET_PRODUCT_LIST, data);
  },
};

export default actions;
