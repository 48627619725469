/**
 * Class for get rights with ez-user API.
 * @module RightsRepository
 */

import axios from 'axios';

function clgError(error) {
  if (error.response) {
    console.log(error.response.data); // eslint-disable-line no-console
    console.log(error.response.status); // eslint-disable-line no-console
    console.log(error.response.headers); // eslint-disable-line no-console
  } else {
    console.log('Error', error.message); // eslint-disable-line no-console
  }
}

export function transformTariff(tariff) {
  return {
    id: tariff.id,
    attributes: tariff.attributes,
    // ...tariff.attributes
  };
}

class RightsRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-user API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = axiosInstance || axios;
    this.apiUrl = apiUrl;
  }

  /**
   * get limits for service by user id
   * @param {string} serviceId id of service
   * @param {string} userId id of user
   */
  async getServiceLimits(service, userId) {
    let limits = {};
    let response = {};
    try {
      response = await this.axios.get(
        `${this.apiUrl}/v1/services/${service}/limits/${userId}`,
        { withCredentials: true },
      );
    } catch (error) {
      clgError(error);
    }
    if (response.status === 200) {
      limits = response.data.data;
    }
    return limits;
  }

  /**
   * get tariff by user id
   * @param {string} userId id of user
   */
  async getTariff(userId) {
    const tariff = {};
    let response = {};
    try {
      response = await this.axios.get(
        `${this.apiUrl}/v1/tariff-current/${userId}`,
        { withCredentials: true },
      );
    } catch (error) {
      clgError(error);
    }
    if (response.status === 200) {
      for (const [key, value] of Object.entries(response.data.data)) {
        tariff[key] = value ? transformTariff(value.data) : null;
      }
    }
    return tariff;
  }
}

export default RightsRepository;
