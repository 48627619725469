const state = {
  widgets: {},
  geolocation: [],
  webinar: {},
  steps: [],
  variations: [],
  revenue: [],
  usersViews: [],
  webinarUsersViews: [],
};

export default state;
