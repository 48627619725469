import Vue from 'vue';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

const mutations = {
  [SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  },
  [ADD_NOTIFICATION](state, notification) {
    state.notifications.push(notification);
  },
  [UPDATE_NOTIFICATION](state, notification) {
    const index = state.notifications.findIndex((n) => n.id === notification.id);
    if (index !== -1) {
      Vue.set(state.notifications, index, notification);
    }
  },
  [REMOVE_NOTIFICATION](state, id) {
    const index = state.notifications.findIndex((n) => n.id === id);
    if (index !== -1) {
      state.notifications.splice(index, 1);
    }
  },
};

export default mutations;
