import {
  URL_APP,
  DOMAIN_CUSTOMERS,
  SERVICE_UUID,
} from '@dmant/ez-env-common';
import updateAbility from '@dmant/ez-lib/src/ability/update';

import get from 'lodash/get';
import Vue from 'vue';

import router from '@/router';
import i18n from '@/i18n';
import * as apiUser from '@/services/apiUser';
import rightsManager from '@/services/rightsManager';
import usersManager from '@/services/usersManager';
import domainsManager from '@/services/domainsManager';
import cloudManager from '@/services/cloudManager';
import loadVeeValidateLocale from '@/helpers/veeValidateLocales';
import { DEFAULT_CURRENCY, ALL_CURRENCY } from '@/enums/currency';
import * as MUTATIONS from './mutations';

const notifyLoggedIn = (user) => {
  if (user) {
    let displayName = user.name;
    if (displayName == null && user.attributes != null) {
      const firstName = get(user, 'attributes.first-name', '');
      const lastName = get(user, 'attributes.last-name', '');
      if (firstName !== '' || lastName !== '') {
        displayName = `${firstName} ${lastName}`.trim();
      }
    }
    if (displayName != null && displayName !== '') {
      const content = i18n.t('user.loggedInAsNotify', { name: displayName });
      Vue.notify({
        data: {
          type: 'primary',
          content,
        },
      });
    }
  }
};

export const LOAD_DOMAINS = 'LOAD_DOMAINS';
export const VALIDATE_SESSION = 'VALIDATE_SESSION';
export const LOGOUT = 'LOGOUT';
export const SUCCESSFUL_AUTH = 'SUCCESSFUL_AUTH';
export const FAIL_AUTH = 'FAIL_AUTH';
export const CHANGE_USER = 'CHANGE_USER';
export const USER_AVATARS = 'USER_AVATARS';

export default {
  async [VALIDATE_SESSION]({ dispatch }, { isRequire }) {
    const result = await apiUser.validateSession();
    if (result.status === 'ok') {
      if (!result.details.attributes.wizard) {
        window.location = `${URL_APP}/wizard/`;
        return new Promise(() => {});
      }
      return dispatch('SUCCESSFUL_AUTH', result.details);
    }
    if (isRequire) {
      apiUser.goToSsoPage();
      return new Promise(() => {});
    }
    return false;
  },

  async [LOGOUT]({ dispatch }) {
    apiUser.logout();
    dispatch('FAIL_AUTH');
  },

  async [SUCCESSFUL_AUTH]({ commit, dispatch }, user) {
    const userLocale = user.attributes.locale;
    i18n.locale = userLocale;
    loadVeeValidateLocale(userLocale);

    const fullUsersList = [user];
    let currentUser = user;

    const savedUser = window.localStorage.getItem('lastSelectedUser');
    if (savedUser) {
      const availableUser = fullUsersList.find((v) => v.id === savedUser);
      if (availableUser) currentUser = availableUser;
    }

    const promises = [
      rightsManager.getServiceLimits(SERVICE_UUID, currentUser.id),
      rightsManager.getTariff(currentUser.id),
      updateAbility(currentUser.id),
    ];
    const [limits, tariff] = await Promise.all(promises);

    const userCurrency = ALL_CURRENCY.find(({ index }) => index === user.attributes.currency);
    const currency = userCurrency || DEFAULT_CURRENCY;

    commit('SET_MAIN_USER', user);
    commit('SET_CURRENCY', currency);
    commit('SET_CURRENT_USER_ID', currentUser.id);
    commit('SET_AUTHORIZED_STATUS', true);
    commit('SET_AVAILABLE_USERS', fullUsersList);
    commit('header/SET_SERVICE_LIMITS', limits);
    commit('header/SET_TARIFF', tariff);
    dispatch('USER_AVATARS', fullUsersList);
    notifyLoggedIn(currentUser);
  },

  [FAIL_AUTH]({ commit }) {
    commit('SET_MAIN_USER', null);
    commit('SET_CURRENT_USER_ID', null);
    commit('SET_AUTHORIZED_STATUS', false);
  },

  async [USER_AVATARS]({ commit }, users) {
    const getFile = async (cloudId) => {
      if (cloudId) {
        try {
          const { data } = await cloudManager.getFile(cloudId);
          return data?.attributes;
        } catch (error) {
          console.log(error);
        }
      }
      return undefined;
    };
    const files = await Promise.all(users.map(({ attributes }) => getFile(attributes.avatar)));
    const result = files.filter(Boolean).reduce((obj, { id, url }) => {
      obj[id] = url;
      return obj;
    }, {});
    commit('SET_USER_AVATARS', result);
  },

  [CHANGE_USER]({ commit, state }, userId) {
    const selectedUser = state.availableUsers.find((v) => v.id === userId);
    if (selectedUser) {
      updateAbility(userId);

      window.localStorage.setItem('lastSelectedUser', userId);
      commit('SET_CURRENT_USER_ID', userId);
      // do something after change user
      router.push('/');
      notifyLoggedIn(selectedUser);
    }
    // console.log("CHANGE_USER ", user);
  },

  async [LOAD_DOMAINS]({ commit }, user) {
    const [slug, domains] = await Promise.all([
      user.attributes?.slug || usersManager.getDefaultDomain(user.id),
      domainsManager.getDomains(user.id),
    ]);
    commit(MUTATIONS.SET_DOMAINS, [{ id: null, name: `${slug}.${DOMAIN_CUSTOMERS}` }, ...domains]);
  },
};
