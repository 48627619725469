import {
  getFunnelSteps,
  getStepTypeByNum,
} from '@/enums/stepTypes';
import FUNNELS from '@/enums/funnels';
import MASTER from '@/enums/funnel-wizard';

export const GET_TYPE_QUERY = 'GET_TYPE_QUERY';
export const GET_FUNNEL = 'GET_FUNNEL';

export const GET_STEPS_NUMS_ALL = 'GET_STEPS_NUMS_ALL';
export const GET_STEPS_NUMS = 'GET_STEPS_NUMS';
export const GET_STEPS = 'GET_STEPS';

function getQuery(slug, master) {
  for (const option of master.options) {
    const { next } = option;
    if (next.type === 'funnel') {
      if (next.slug === slug) {
        return { [master.name]: option.value };
      }
    } else {
      const query = getQuery(slug, next);
      if (query) {
        return { [master.name]: option.value, ...query };
      }
    }
  }
  return null;
}

export default {
  [GET_TYPE_QUERY]: (state) => getQuery(state.typeSlug, MASTER),

  [GET_FUNNEL](state) {
    return FUNNELS.find((f) => f.slug === state.typeSlug) || {};
  },

  [GET_STEPS_NUMS_ALL](state, getters) {
    const funnelType = getters[GET_FUNNEL].value;
    return getFunnelSteps(funnelType) || [];
  },

  [GET_STEPS_NUMS](state, getters) {
    return getters[GET_STEPS_NUMS_ALL]
      .filter((num) => !process.env[`VUE_APP_FUNNEL_WIZARD_STEP_${num}_TEMPLATE`]);
  },

  [GET_STEPS](state, getters) {
    return getters[GET_STEPS_NUMS].map(getStepTypeByNum);
  },
};
