import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import header from './header';
import builder from './builder';
import funnel from './funnel';
import funnelStatistic from './funnelStatistic';
import funnels from './funnels';
import dashboard from './dashboard';
import notifications from './notifications';
import wizard from './wizard';
import webinar from './webinar';

import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  // eslint-disable-next-line no-shadow
  reducer: (state) => ({
    wizard: {
      form: state.wizard.form,
    },
  }),
  // modules: [{ wizard: 'form' }],
});

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  debug: process.env.NODE_ENV !== 'production',
  state,
  mutations,
  actions,
  getters,
  modules: {
    header,
    builder,
    funnel,
    funnelStatistic,
    funnels,
    dashboard,
    notifications,
    wizard,
    webinar,
  },
  plugins: [vuexSession.plugin],
});

export default store;
