import { URL_PREVIEW_URL2PNG } from '@dmant/ez-env-common';
import md5 from 'md5';

const API_KEY = 'PDD290F44389F3D';
const API_SECRET = 'S_FD26653B61B18';
const API_URL = 'https://api.url2png.com/v6';

function getLangPath(locale) {
  if (locale) {
    const [lang] = /^\w\w/.exec(locale);
    return lang.toLowerCase();
  }
  return '';
}

function getPreviewUrl(template, locale) {
  if (locale) {
    const prefix = getLangPath(locale);
    return template[`preview-${prefix}-cloud`] || template['preview-cloud'];
  }
  return template['preview-cloud'];
}

export default function templatePreview(template, size = 500, unique = '', locale = '') {
  const getUrl2Png = () => {
    const params = new URLSearchParams();
    params.set('thumbnail_max_width', size);
    params.set(
      'url',
      `${URL_PREVIEW_URL2PNG}/${template.id}/${getLangPath(locale)}`,
    );
    if (unique) {
      params.set('unique', unique);
    }
    const builderTemplateUrl = `?${params.toString()}`;
    const token = md5(builderTemplateUrl + API_SECRET);
    return `${API_URL}/${API_KEY}/${token}/png/${builderTemplateUrl}`;
  };
  return getPreviewUrl(template, locale) || getUrl2Png();
}
