import Vue from 'vue';
import { updateField } from 'vuex-map-fields';

export const INIT = 'INIT';
export const SET_STEPS = 'SET_STEPS';
export const SET_STEP_PROPS = 'SET_STEP_PROPS';
export const SET_ARCHIVE_LENGTH = 'SET_ARCHIVE_LENGTH';
export const SET_ARCHIVE_VARIATION_LENGTH = 'SET_ARCHIVE_VARIATION_LENGTH';
export const ADD_TO_ARCHIVE = 'ADD_TO_ARCHIVE';
export const INSERT_STEP = 'INSERT_STEP';
export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';
export const SET_DIALOG_STEP = 'SET_DIALOG_STEP';
export const SHOW_DIALOG_STEP = 'SHOW_DIALOG_STEP';
export const SET_STEP_ATTRIBUTES = 'SET_STEP_ATTRIBUTES';
export const SET_PAYMENT_PROVIDER_ID = 'SET_PAYMENT_PROVIDER_ID';
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';
export const SET_STEP_PRODUCT = 'SET_STEP_PRODUCT';
export const DELETE_STEP_PRODUCT = 'DELETE_STEP_PRODUCT';
export const SET_STEP_FALLBACK = 'SET_STEP_FALLBACK';
export const DELETE_STEP_FALLBACK = 'DELETE_STEP_FALLBACK';
export const SET_VARIATIONS = 'SET_VARIATIONS';
export const ADD_VARIATION = 'ADD_VARIATION';
export const CREATE_VARIATION = 'CREATE_VARIATION';
export const RESTORE_VARIATION = 'RESTORE_VARIATION';
export const CHANGE_VARIATIONS_PERCENTS = 'CHANGE_VARIATIONS_PERCENTS';
export const SET_VARIATION_TEMPLATE = 'SET_VARIATION_TEMPLATE';
export const DELETE_VARIATION_TEMPLATE = 'DELETE_VARIATION_TEMPLATE';
export const DELETE_STEP_BY_INDEX = 'DELETE_STEP_BY_INDEX';
export const MOVE_STEPS = 'MOVE_STEPS';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const SET_VISIBLE_ERROR_BUTTON = 'SET_VISIBLE_ERROR_BUTTON';

const mutations = {
  updateField,
  [INIT]: (state, init) => {
    state.init = init;
    state.dialogStep = null;
    state.steps = null;
    state.archiveLength = null;
    state.errors = [];
  },
  [SET_STEPS]: (state, steps) => {
    state.steps = steps;
  },
  [SET_STEP_PROPS]: (state, { step, props }) => {
    const index = state.steps.indexOf(step);
    if (index === -1) {
      throw new Error('step is not exists');
    }
    Vue.set(state.steps, index, props);
  },
  [SET_ARCHIVE_LENGTH]: (state, archiveLength) => {
    state.archiveLength = archiveLength;
  },
  [SET_ARCHIVE_VARIATION_LENGTH]: (state, archiveVariationLength) => {
    state.archiveVariationLength = archiveVariationLength;
  },
  [ADD_TO_ARCHIVE]: (state, { step, variations }) => {
    step.archive.push(...variations);
    state.archiveLength += variations.length;
    state.archiveVariationLength += variations.length;
  },
  [SHOW_DIALOG]: (state, dialog) => {
    state.dialogStep = null;
    state[dialog] = true;
  },
  [HIDE_DIALOG]: (state, dialog) => {
    state.dialogStep = null;
    state[dialog] = false;
  },
  [SET_DIALOG_STEP]: (state, step) => {
    state.dialogStep = step;
  },
  [SHOW_DIALOG_STEP]: (state, { step, dialog }) => {
    state.dialogStep = step;
    state[dialog] = true;
  },
  [SET_STEP_ATTRIBUTES]: (state, { step, attributes }) => {
    for (const [key, value] of Object.entries(attributes)) {
      Vue.set(step.attributes, key, value);
    }
  },
  [SET_PRODUCT_LIST]: (state, payload) => {
    state.productList = payload;
  },
  [SET_PAYMENT_PROVIDER_ID]: (state, payload) => {
    state.paymentProviderId = payload;
  },
  [SET_STEP_PRODUCT]: (state, { step, product }) => {
    Vue.set(step, 'product', product);
  },
  [DELETE_STEP_PRODUCT]: (state, step) => {
    Vue.delete(step, 'product');
  },
  [SET_STEP_FALLBACK]: (state, { step, fallback }) => {
    Vue.set(step, 'fallback', fallback);
  },
  [DELETE_STEP_FALLBACK]: (state, step) => {
    Vue.delete(step, 'fallback');
  },
  [ADD_VARIATION]: (state, { step, variation, template }) => {
    variation.template = template;
    step.variations.push(variation);
  },
  [CREATE_VARIATION]: (state, { template, step, percent }) => {
    const prevOrderIndex = step.variations.length
      ? step.variations[step.variations.length - 1].attributes.index
      : 0;
    step.variations.push({
      attributes: {
        percent,
        index: 100 + prevOrderIndex,
      },
      template,
    });
  },
  [RESTORE_VARIATION]: (state, {
    step, variation, template, index,
  }) => {
    step.archive.splice(index, 1);
    state.archiveLength -= 1;
    state.archiveVariationLength -= 1;
    const prevOrderIndex = step.variations.length
      ? step.variations[step.variations.length - 1].attributes.index
      : 0;
    Vue.set(variation.attributes, 'index', 100 + prevOrderIndex);
    Vue.set(variation.attributes, 'percent', 0);
    if (template) {
      Vue.set(variation, 'template', template);
    }
    step.variations.push(variation);
  },
  [CHANGE_VARIATIONS_PERCENTS]: (state, { variations, percents }) => {
    variations.forEach((variation, index) => {
      Vue.set(variation.attributes, 'percent', percents[index]);
    });
  },
  [SET_VARIATION_TEMPLATE]: (state, { variation, template }) => {
    Vue.set(variation, 'template', template);
    Vue.set(variation.attributes, 'template-id', template.id);
  },
  [DELETE_VARIATION_TEMPLATE]: (state, variation) => {
    Vue.delete(variation, 'template');
    Vue.set(variation.attributes, 'template-id', null);
  },
  [SET_VARIATIONS]: (state, { step, variations }) => {
    if (variations.length === 1) {
      Vue.set(variations[0].attributes, 'main', true);
    }
    Vue.set(step, 'variations', variations);
  },
  [INSERT_STEP]: (state, { step, index }) => {
    state.steps.splice(index, 0, step);
  },
  [DELETE_STEP_BY_INDEX]: (state, index) => {
    const [step] = state.steps.splice(index, 1);
    state.archiveLength -= step.archive.length;
  },
  [MOVE_STEPS]: (state, { sourceIndex, targetIndex, count }) => {
    const steps = state.steps.splice(sourceIndex, count);
    state.steps.splice(targetIndex, 0, ...steps);
  },
  [ADD_ERROR]: (state, payload) => {
    state.errors.push(payload);
  },
  [REMOVE_ERROR]: (state, itemId) => {
    const index = state.errors.findIndex((item) => item.errorId === itemId);
    if (index >= 0) {
      const [{ selector }] = state.errors.splice(index, 1);
      document.querySelector(selector)?.classList.remove('on-hover');
    }
  },
  [SET_VISIBLE_ERROR_BUTTON]: (state, payload) => {
    state.isErrorButtonVisible = payload;
  },
};

export default mutations;
