import { URL_API_USER, URL_AUTH } from '@dmant/ez-env-common';
import axios from '@/services/axios';

export function goToSsoPage(continuePage = window.location.href) {
  const goToUrl = continuePage
    ? `${URL_AUTH}?continue=${continuePage}`
    : URL_AUTH;
  window.location.href = goToUrl;
}

export async function validateSession() {
  let rawResponse = {};
  let result = {
    status: 'fail',
    details: 'Unknown error',
  };

  await axios
    .get(`${URL_API_USER}/v1/identity`, {
      withCredentials: true,
    })
    .then((response) => {
      rawResponse = response;
    })
    .catch((error) => {
      if (error.response) {
        rawResponse = error.response;
      }
    });

  switch (rawResponse.status) {
    case 200:
      result = {
        status: 'ok',
        details: { ...rawResponse.data.data, manage: true },
      };
      result.details.attributes.wizard = true;
      break;

    default:
      result = {
        status: 'fail',
        details: rawResponse.data,
      };
      break;
  }

  return result;
}

export function updateAccount(id, attributes) {
  return axios.patch(`${URL_API_USER}/v1/users/${id}`, {
    data: {
      attributes,
      type: 'user',
    },
  }, {
    withCredentials: true,
  });
}

export function logout() {
  return axios.get(`${URL_API_USER}/v1/logout`, {
    withCredentials: true,
  });
}
