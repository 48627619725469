export const WEBINAR = 'webinar';
export const SALESPAGE = 'salespage';
export const OPTIN_SALESPAGE = 'optin_salespage';
export const ORDERFORM = 'orderform';

export const OPTIN = 'optin';
export const FREE_WEBINAR = 'free_webinar';

export const FREE_WEBINAR_AFFILIATE = 'free_webinar_affiliate';
export const SALESPAGE_AFFILIATE = 'salespage_affiliate';
export const OPTIN_SALESPAGE_AFFILIATE = 'optin_salespage_affiliate';
export const WEBINAR_APPLICATIONFORM = 'webinar_canditature';
export const FREE_WEBINAR_APPLICATIONFORM = 'free_webinar_canditature';
export const OPTIN_APPLICATIONFORM = 'optin_applicationform';
export const APPLICATIONFORM = 'applicationform';

export const WEBINAR_TYPES = [
  WEBINAR,
  FREE_WEBINAR,
  FREE_WEBINAR_AFFILIATE,
  FREE_WEBINAR_APPLICATIONFORM,
  WEBINAR_APPLICATIONFORM,
];

export const FIRST_STEP_GROUPS = [
  {
    name: 'sellProducts',
    orderTypes: [SALESPAGE, OPTIN_SALESPAGE, WEBINAR, ORDERFORM],
  },
  {
    name: 'collectEmails',
    orderTypes: [OPTIN, FREE_WEBINAR],
  },
  {
    name: 'specialFunnels',
    orderTypes: [
      FREE_WEBINAR_AFFILIATE,
      FREE_WEBINAR_APPLICATIONFORM,
      WEBINAR_APPLICATIONFORM,
      SALESPAGE_AFFILIATE,
      OPTIN_SALESPAGE_AFFILIATE,
      OPTIN_APPLICATIONFORM,
      APPLICATIONFORM,
    ],
  },
];
