import cloneDeepWith from 'lodash/cloneDeepWith';
import isString from 'lodash/isString';
import en from './en_EN.json';

const replace = (v) => {
  if (isString(v)) {
    return v.replace(/(^|\s*\|\s*)/g, '$1⬛ ');
  }
};
const result = process.env.VUE_APP_I18N_DEBUG ? cloneDeepWith(en, replace) : en;
export default result;
