const state = {
  categories: [],
  themes: [],
  templates: [],
  theme: {},
  selectedTemplates: {
  },
};

export default state;
