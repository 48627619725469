import data from './dashboard.json';

const dashboard = {
  namespaced: true,
  state: {
    title: 'dashboard',
    data: [],
    total: 0,
    totalPages: 0,
    loading: true,
  },
  mutations: {
    // eslint-disable-next-line no-shadow
    GET_DATA(state, { data, total, limit }) {
      state.data = data;
      state.total = total;
      state.totalPages = Math.ceil(total / limit);
      state.loading = false;
    },
  },
  actions: {
    // getData({ commit }, { limit, page = 1 }) {
    //   setTimeout(() => {
    //     commit('GET_DATA', {
    //       data: [...data.slice((page - 1) * limit, (page - 1) * limit + limit)],
    //       total: data.length,
    //       limit,
    //     });
    //   }, 250);
    // },
    getData({ commit }, { limit, page = 1 }) {
      commit('GET_DATA', {
        data: [...data.slice((page - 1) * limit, (page - 1) * limit + limit)],
        total: data.length,
        limit,
      });
    },
  },
};

export default dashboard;
