import get from 'lodash/get';
import pick from 'lodash/pick';
import { camelCase } from 'change-case';
import getApiAxios from './lib/apiAxios';

/**
 * Class for getting different funnel statstic.
 * @module FunnelsStatisticRepository
 */

function clgError(error) {
  if (error.response) {
    console.log(error.response.data); // eslint-disable-line no-console
    console.log(error.response.status); // eslint-disable-line no-console
    console.log(error.response.headers); // eslint-disable-line no-console
  } else if (error.request) {
    console.log(error.request); // eslint-disable-line no-console
  } else {
    console.log('Error', error.message); // eslint-disable-line no-console
  }
  console.log(error.config); // eslint-disable-line no-console
}

const geolocationFields = [
  'customers',
  'customer-value',
  'lead-gain',
  'leads',
  'revenue',
  // 'sales',
  // 'total-views',
  // 'unique-views',
  'unique-visitors',
  'visitor-value',
];

function transformGeolocation2flatFormat(country) {
  const attributes = get(country, 'attributes', {});
  const field = {};
  for (const [key, value] of Object.entries(pick(attributes, geolocationFields))) {
    field[camelCase(key)] = value;
  }
  return {
    id: country.id,
    ...field,
  };
}

function transform2flatFormat(data) {
  const attributes = get(data, 'attributes', {});
  const fields = Object.keys(attributes).reduce((result, attr) => {
    result[camelCase(attr)] = attributes[attr];
    return result;
  }, {});
  fields.id = data.id;
  return fields;
}

function transformWidgets2flatFormat(data) {
  const attributes = get(data, 'attributes', {});
  return Object.keys(attributes).reduce((result, attr) => {
    result[camelCase(attr)] = attributes[attr];
    return result;
  }, {});
}

class FunnelsStatisticRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-builder API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = getApiAxios(axiosInstance, { baseURL: `${apiUrl}/v1/` });
    this.apiUrl = apiUrl;
  }

  /**
   * get geodata stat
   *
   * @param {*} params
   */
  async getGeoData(revisionId) {
    const response = await this.axios
      .get('funnel/geolocation-total', {
        params: {
          'filter[revisionid][eq]': revisionId,
        },
      })
      .catch((error) => clgError(error));
    return response && response.data.data
      ? response.data.data.map((country) => transformGeolocation2flatFormat(country))
      : [];
  }

  /**
   * get device tracking data
   *
   * @param {*} params
   */
  async getDevicesData(revisionId) {
    const response = await this.axios
      .get('funnel/device', {
        params: {
          'filter[revisionid][eq]': revisionId,
        },
      })
      .catch((error) => clgError(error));
    return response && response.data
      ? transformWidgets2flatFormat(response.data)
      : {};
  }

  /**
   * get widgets for user and revenue (count 8)
   *
   * @param {*} params
   */
  async getWidgetsData(revisionId) {
    const response = await this.axios
      .get('funnel/widgets', {
        params: {
          'filter[revisionid][eq]': revisionId,
        },
      })
      .catch((error) => clgError(error));
    return response && response.data.data && response.data.data.length
      ? transformWidgets2flatFormat(response.data.data[0])
      : {};
  }

  /**
   * get revenue overview (upsell, downsell, order form)
   *
   * @param {*} params
   */
  async getRevenueData({ revisionId, stepsIds }) {
    const params = {};
    params['filter[revisionid][eq]'] = revisionId;
    stepsIds.forEach((s, i) => {
      params[`filter[steps][${i}][and][step-id][eq]`] = s;
    });
    const response = await this.axios
      .get('funnel/revenue-overview', {
        params,
      })
      .catch((error) => clgError(error));
    return response && response.data.data
      ? response.data.data.map((revenue) => transform2flatFormat(revenue))
      : [];
  }

  /**
   * get pages views graph data
   *
   * @param {*} params
   */
  async getUsersPageViewsData(revisionId) {
    const response = await this.axios
      .get('funnel/graphs/views', {
        params: {
          'filter[revisionid][eq]': revisionId,
        },
      })
      .catch((error) => clgError(error));
    return response && response.data.data
      ? response.data.data.map((item) => transform2flatFormat(item))
      : [];
  }

  /**
   * get steps data
   *
   * @param {*} params
   */
  async getStepsData({ revisionId, steps }) {
    const params = {};
    steps.forEach((s, i) => {
      params[`filter[card][${i}][and][step-id][eq]`] = s.id;
      params[`filter[card][${i}][and][step-type][eq]`] = s.type;
    });
    params['filter[revisionid][eq]'] = revisionId;
    const response = await this.axios
      .get('funnel/pages-cards', {
        params,
      })
      .catch((error) => clgError(error));
    return {
      steps: response?.data?.data?.map?.((item) => transform2flatFormat(item)) || [],
      variations: response?.data?.included?.data?.map?.((item) => {
        const result = transform2flatFormat(item);
        result.rate = Number(result.rate).toFixed(2).toString().replace(/(\.0+$|0+$)/g, '');
        return result;
      }) || [],
    };
  }

  /**
   * get variations conversation rate
   *
   * @param {*} params
   */
  async getVariationsConversationRate({ revisionId, steps }) {
    const params = {
      'fields[pages-cards-webinar-registration]': 'id',
    };
    steps.forEach((s, i) => {
      params[`filter[card][${i}][and][step-id][eq]`] = s.id;
      params[`filter[card][${i}][and][step-type][eq]`] = s.type;
    });
    params['filter[revisionid][eq]'] = revisionId;
    const response = await this.axios
      .get('funnel/pages-cards', {
        params,
      })
      .catch((error) => clgError(error));
    return response && response.data.included
      ? response.data.included.data.map((item) => {
        const result = transform2flatFormat(item);
        result.rate = Number(result.rate).toFixed(2).toString().replace(/(\.0+$|0+$)/g, '');
        return result;
      })
      : [];
  }

  async resetStatistics(revisionId) {
    return this.axios.get('funnel/clear', {
      params: { 'filter[revisionid][eq]': revisionId },
    });
  }
}

export default FunnelsStatisticRepository;
