export const SET_TIMELINE = 'SET_TIMELINE';
export const SET_DURATION = 'SET_DURATION';
export const SORT_TIMELINE = 'SORT_TIMELINE';

const mutations = {
  [SET_TIMELINE]: (state, payload) => {
    state.timeline = payload;
  },
  [SET_DURATION]: (state, payload) => {
    state.duration = payload;
  },
  [SORT_TIMELINE]: (state) => {
    state.timeline.sort((a, b) => {
      const timeA = a['time-code'] || a['time-code-start'];
      const timeB = b['time-code'] || b['time-code-start'];

      return timeA - timeB;
    });
  },
};

export default mutations;
