import { URL_API_FUNNELS } from '@dmant/ez-env-common';
import axios from 'axios';
import moment from 'moment';
import { paramCase, camelCase } from 'change-case';
import Jsonapi from 'jsona';

const jsonapi = new Jsonapi();

function clgError(error) {
  const result = {
    error: true,
    message: '',
    data: undefined,
  };
  if (error.response) {
    console.log(error.response.data); // eslint-disable-line no-console
    console.log(error.response.status); // eslint-disable-line no-console
    console.log(error.response.headers); // eslint-disable-line no-console
    result.data = error.response.data;
    if (result.data.errors) {
      result.message = result.data.errors
        .reduce((row, err) => {
          if (!row.includes(err.detail)) row.push(err.detail);
          return row;
        }, [])
        .join('; ');
    } else result.message = 'ERROR!';
  } else {
    console.log('Error', error.message); // eslint-disable-line no-console
    result.message = error.message;
  }
  return result;
}

function getApiAttributes(funnel) {
  const attributes = {};
  for (const [key, value] of Object.entries(funnel)) {
    attributes[paramCase(key)] = value;
  }
  return attributes;
}

function mapAttributes(attributes) {
  const funnel = {};
  for (const [key, value] of Object.entries(attributes)) {
    funnel[camelCase(key)] = value;
  }
  if (funnel.imprint === null) {
    funnel.imprint = '';
  }
  if (funnel.privacyPolicy === null) {
    funnel.privacyPolicy = '';
  }
  funnel.dtCreate = moment.utc(funnel.dtCreate);
  funnel.dtUpdate = moment.utc(funnel.dtUpdate);
  funnel.stepCount = attributes['step-count'];
  return funnel;
}

function transform2apiFromat({
  userId, groupId, themeId, templates, ...funnel
}) {
  const attributes = getApiAttributes(funnel);

  const relationships = {
    user: {
      data: {
        type: 'user',
        id: userId,
      },
    },
  };
  if (groupId && groupId !== 'ungrouped') {
    relationships.group = {
      data: {
        type: 'group',
        id: groupId,
      },
    };
  }
  if (themeId) {
    relationships.theme = {
      data: {
        type: 'theme',
        id: themeId,
      },
    };
  }
  delete attributes.revisionId;

  const result = {
    data: {
      type: 'funnels',
      attributes,
      relationships,
      meta: {
        templates,
      },
    },
  };
  return result;
}

function getRelationship(relationships, type) {
  const { data } = relationships[type];
  return data && data.length > 0 && data[0];
}

function transform2flatFormat({ id, attributes, relationships }, included = []) {
  const group = getRelationship(relationships, 'group');
  const revision = getRelationship(relationships, 'revisions');
  const userRel = getRelationship(relationships, 'user');
  const user = userRel
    && (included.find((row) => userRel.id === row.id && userRel.type === row.type) || userRel);

  return {
    user,
    id,
    groupId: group ? group.id : 'ungrouped',
    revisionId: revision && revision.id,
    checked: false,
    ...mapAttributes(attributes),
  };
}

/**
 * Class for manupulate funnels with ez-funnel API.
 * @module FunnelsRepository
 */
class FunnelsRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   */
  constructor(axiosInstance) {
    this.axios = axiosInstance || axios;
  }

  /**
   * private method for get filtered list of funnels
   * @param {string} params jsonapi parameters (page, include, filter)
   */
  async getList(params, addUrl = null) {
    const response = await this.axios.get(
      `${URL_API_FUNNELS}/v1/funnels${addUrl || ''}`,
      {
        params,
        withCredentials: true,
        validateStatus: (status) => status === 200,
      },
    );
    const { included, meta = {} } = response.data;
    const data = response.data.data.map((row) => {
      const funnel = transform2flatFormat(row);

      if (included && row.relationships && row.relationships['cloned-from']) {
        for (const rel of row.relationships['cloned-from'].data) {
          const { id, attributes } = included
            .find((inc) => inc.type === rel.type && inc.id === rel.id);
          funnel.clonedFrom = {
            id,
            shareHash: attributes['share-hash'],
          };
        }
      }

      return funnel;
    });
    return { data, meta };
  }

  /**
   * get all active funnels by user id
   * @param {string} userId id of user
   */
  getFunnels(userId) {
    const params = {
      include: 'group,cloned-from',
      'fields[group]': 'id',
      'fields[cloned-from]': 'id,share-hash',
      sort: '-dt-update',
      'page[size]': 1000,
      'filter[funnels][and][user][eq]': userId,
      'filter[funnels][and][archive][eq]': false,
    };
    return this.getList(params);
  }

  /**
   * get all active funnels by user id
   * @param {string} userId id of user
   */
  getLatestFunnels(userId) {
    const params = {
      sort: '-dt-update',
      'page[size]': 5,
      'filter[funnels][and][user][eq]': userId,
      'filter[funnels][and][archive][eq]': false,
    };
    return this.getList(params);
  }

  /**
   * @param {string} funnelId id of user
   */
  async getFunnel(funnelId, fields = []) {
    const params = {
      include: 'user,group,revisions', // ,cloned-from
    };
    if (fields.length) {
      params['fields[funnels]'] = fields.join(',');
    }
    params['fields[user]'] = 'id,slug';
    const response = await this.axios.get(
      `${URL_API_FUNNELS}/v1/funnels/${funnelId}`,
      {
        params,
        withCredentials: true,
        validateStatus: (status) => status === 200,
      },
    );
    return transform2flatFormat(response.data.data, response.data.included);
  }

  /**
   * get archive funnels by user id
   * @param {string} userId id of user
   */
  getArchive(userId, pages) {
    const params = {
      sort: '-dt-update',
      'page[number]': pages.page,
      'page[size]': pages.limit,
      'filter[funnels][and][user][eq]': userId,
      'filter[funnels][and][archive][eq]': true,
    };
    return this.getList(params);
  }

  /**
   * get archive funnels by user id
   * @param {string} userId id of user
   */
  async getArchiveCount(userId) {
    const params = {
      'page[number]': 1,
      'page[size]': 1,
      'filter[funnels][and][user][eq]': userId,
      'filter[funnels][and][archive][eq]': true,
    };
    const funnels = await this.getList(params);
    return funnels.meta.count;
  }

  /**
   * get count all funnels by user id
   * @param {string} userId id of user
   */
  async getAllCount(userId) {
    const params = {
      'page[number]': 1,
      'page[size]': 1,
      'filter[user][eq]': userId,
    };
    const funnels = await this.getList(params);
    return funnels.meta.count;
  }

  /**
   * get ungrouped funnels by user id
   * @param {string} userId id of user
   */
  async getUngroupedCount(userId) {
    const params = {
      'page[number]': 1,
      'page[size]': 1,
      'filter[funnels][and][user][eq]': userId,
      'filter[funnels][and][archive][eq]': false,
    };
    const addString = '?filter[funnels][and][group][isNull]';
    const funnels = await this.getList(params, addString);
    return funnels.meta.count;
  }

  /**
   * Create new funnel
   * @param {object} funnel attributes of new funnel
   * @param {string} userId id of user, which own new funnel
   */
  async create(funnel, userId) {
    let result = null;
    let response = {};
    try {
      funnel.userId = userId;
      const newFunnel = transform2apiFromat(funnel);
      response = await this.axios.post(`${URL_API_FUNNELS}/v1/funnels`, newFunnel, {
        withCredentials: true,
      });
    } catch (error) {
      result = clgError(error);
    }

    if (response.status === 200) {
      result = transform2flatFormat(response.data.data);
    }
    return result;
  }

  /**
   * update existed funnel
   * @param {string} funnel funnel id
   * @param {string} userId id of user, which own funnel
   */
  async update(funnel, userId) {
    const updatedFunnel = transform2apiFromat({ ...funnel, userId });
    const response = await this.axios.patch(
      `${URL_API_FUNNELS}/v1/funnels/${funnel.id}`,
      updatedFunnel,
      {
        params: {
          include: 'user,group,revisions',
        },
        withCredentials: true,
      },
    );
    if (response.status === 200) {
      return transform2flatFormat(response.data.data);
    }
    throw new Error('response.status code !== 200');
  }

  async clone(id, attributes, userId) {
    const response = await this.axios.post(
      `${URL_API_FUNNELS}/v1/funnels/${id}/clone`,
      transform2apiFromat({ userId, ...attributes }),
      {
        params: {
          include: 'user,group,revisions',
        },
        withCredentials: true,
      },
    );
    if (response.status === 200) {
      return transform2flatFormat(response.data.data);
    }
    throw new Error('response.status code !== 200');
  }

  async shareImport(hash) {
    const response = await this.axios.post(
      `${URL_API_FUNNELS}/v1/funnels-share/${hash}`,
      {},
      { withCredentials: true },
    );
    if (response.status === 200) {
      return response.data.data;
    }
    throw new Error('response.status code !== 200');
  }

  async resetWebinarLoginTemplate(funnelId) {
    const response = await this.axios.get(
      `${URL_API_FUNNELS}/v1/funnels/${funnelId}/reset-webinar-login-template`,
      {
        withCredentials: true,
        validateStatus: (status) => status === 200,
      },
    );
    return jsonapi.deserialize(response.data);
  }
}

export default FunnelsRepository;
