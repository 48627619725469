/**
 * Class for manupulate webinars with ez-webinar API.
 * @module WebinarnRepository
 */

import axios from 'axios';
import moment from 'moment';
import Jsonapi from 'jsona';

const jsonapi = new Jsonapi();

function clgError(error) {
  if (error.response) {
    console.log(error.response.data); // eslint-disable-line no-console
    console.log(error.response.status); // eslint-disable-line no-console
    console.log(error.response.headers); // eslint-disable-line no-console
  } else {
    console.log('Error', error.message); // eslint-disable-line no-console
  }
}

function transform2flatFormat(webinar) {
  return {
    id: webinar.id,
    name: webinar.attributes.name,
  };
}
function transform2flatFormatOffer(offer) {
  return {
    id: offer.id,
    title: offer.attributes.title,
  };
}

class WebinarnRepository {
  /**
   * @param {object} axiosInstance axios instance(can be undefined)
   * @param {string} apiUrl url to ez-webinar API
   */
  constructor(axiosInstance, apiUrl) {
    this.axios = axiosInstance || axios;
    this.apiUrl = apiUrl;
  }

  /**
   * get webinars by user id
   * @param {string} userId id of user
   */
  async getWebinars(userId, { page = 1, limit = 1000 } = {}) {
    let webinars = [];
    let response = {};
    const url = `${this.apiUrl}/v1/webinars`;
    const params = {
      'page[number]': page,
      'page[size]': limit,
      'fields[webinars]': 'name',
      'filter[webinars][and][archive][eq]': 0,
      'filter[webinars][and][owner][eq]': userId,
      sort: 'name',
    };
    try {
      response = await this.axios.get(url, { withCredentials: true, params });
    } catch (error) {
      clgError(error);
    }
    if (response.status === 200) {
      webinars = response.data.data.map(transform2flatFormat);
    }
    return webinars;
  }

  async get(webinarId) {
    const url = `${this.apiUrl}/v1/webinars/${webinarId}`;
    const response = await this.axios.get(url, { withCredentials: true });
    if (response.status === 200) {
      return jsonapi.deserialize(response.data);
    }
    throw new Error(`${response.status} code !== 200`);
  }

  async getOffers(webinarId) {
    const url = `${this.apiUrl}/v1/offers`;
    const params = {
      'filter[webinar][eq]': webinarId,
    };
    const response = await this.axios.get(url, { withCredentials: true, params });
    if (response.status === 200) {
      return response.data.data.map(transform2flatFormatOffer);
    }
    throw new Error(`${response.status} code !== 200`);
  }

  async getWebinarsSessions({ webinarId, isReplay, limit }) {
    const url = `${this.apiUrl}/v1/webinars-sessions`;

    // const SESSION_TYPE_SCHEDULED = 1;
    const SESSION_TYPE_JUST_IN_TIME = 2;

    // const SESSION_STATUS_FUTURE = 1;
    // const SESSION_STATUS_NOW = 2;
    const SESSION_STATUS_OUTDATED = 3;

    const params = {
      'filter[webinars-sessions][and][webinar][eq]': webinarId,
      'filter[webinars-sessions][and][hidden][eq]': false,
      'filter[webinars-sessions][and][type][neq]': SESSION_TYPE_JUST_IN_TIME,
      [`filter[webinars-sessions][and][status][${isReplay ? 'eq' : 'neq'}]`]: SESSION_STATUS_OUTDATED,
      [`filter[webinars-sessions][and][datetime-iso][${isReplay ? 'lte' : 'gte'}]`]: moment().utc().toISOString(),
      include: 'schedule',
      sort: `${isReplay ? '-' : ''}datetime`,
      'page[size]': limit,
    };

    const response = await axios.get(url, { withCredentials: true, params });
    return response.status === 200 ? jsonapi.deserialize(response.data) : [];
  }

  /**
   * get webinar pages views graph data
   *
   * @param {*} params
   */
  async getUsersWebinarPageViewsData({ webinarId, funnelId }) {
    const url = `${this.apiUrl}/v1/statistics-attendees/${webinarId}/${funnelId}`;
    const response = await this.axios.get(url, { withCredentials: true });
    if (response.status === 200) {
      const x = [];
      response.data.data.map((s) => x.push([+s.second.toFixed(0), s.value]));
      // always add 0,0 point
      x.unshift([0, 0]);
      return x;
    }
    throw new Error(`${response.status} code !== 200`);
  }

  async getTimeLine(webinarID, include) {
    const includeItems = include.length === 0
      ? 'live-chats,feedbacks,sticky-messages,sales,files,polls,offers'
      : include.join(',');

    const response = await axios.get(
      `${this.apiUrl}/v1/webinars/${webinarID}?include=${includeItems}`,
      { withCredentials: true },
    );

    if (response.status !== 200) {
      return [];
    }

    const data = jsonapi.deserialize(response.data);

    return {
      'live-chats': data['live-chats'] || [],
      feedbacks: data.feedbacks || [],
      'sticky-messages': data['sticky-messages'] || [],
      sales: data.sales || [],
      files: data.files || [],
      polls: data.polls || [],
      offers: data.offers || [],
    };
  }
}

export default WebinarnRepository;
